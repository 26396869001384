export const TUTORIEL_LIST_REQUEST = 'TUTORIEL_LIST_REQUEST';
export const TUTORIEL_LIST_SUCCESS = 'TUTORIEL_LIST_SUCCESS';
export const TUTORIEL_LIST_FAIL = 'TUTORIEL_LIST_FAIL';

export const TUTORIEL_ADD_REQUEST = 'TUTORIEL_ADD_REQUEST';
export const TUTORIEL_ADD_SUCCESS = 'TUTORIEL_ADD_SUCCESS';
export const TUTORIEL_ADD_FAIL = 'TUTORIEL_ADD_FAIL';
export const TUTORIEL_ADD_RESET = 'TUTORIEL_ADD_RESET';

export const TUTORIEL_DELETE_REQUEST = 'TUTORIEL_DELETE_REQUEST';
export const TUTORIEL_DELETE_SUCCESS = 'TUTORIEL_DELETE_SUCCESS';
export const TUTORIEL_DELETE_FAIL = 'TUTORIEL_DELETE_FAIL';
export const TUTORIEL_DELETE_RESET = 'TUTORIEL_DELETE_RESET';

export const TUTORIEL_UPDATE_REQUEST = 'TUTORIEL_UPDATE_REQUEST';
export const TUTORIEL_UPDATE_SUCCESS = 'TUTORIEL_UPDATE_SUCCESS';
export const TUTORIEL_UPDATE_FAIL = 'TUTORIEL_UPDATE_FAIL';
export const TUTORIEL_UPDATE_RESET = 'TUTORIEL_UPDATE_RESET';
