import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

import { informationListAction } from '../../redux/actions/informationActions';
import { dataListAction } from '../../redux/actions/dataActions';
import { parametreListAction } from '../../redux/actions/parametresActions';

import DataCounter from '../../components/DataCounter/DataCounter';

import './Accueil.css'; // Assurez-vous d'importer votre fichier CSS

function Accueil() {
  const dispatch = useDispatch();

  const informationList = useSelector((state) => state.informationList);
  const { informations, loading, error } = informationList;

  const dataList = useSelector((state) => state.dataList);
  const { datas, loading: loadingData, error: errorData } = dataList;

  const parametreList = useSelector((state) => state.parametreList);
  const { parametres } = parametreList;

  const [affichageData, setAffichageData] = useState();
  const [selectedInformation, setSelectedInformation] = useState({});

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    dispatch(dataListAction());
    dispatch(informationListAction());
    dispatch(parametreListAction());
  }, [dispatch]);

  useEffect(() => {
    if (parametres && parametres.length > 0) {
      let affichage_data = parametres.find(param => param.nom === 'affichage_data');
      if (affichage_data) {
        setAffichageData(affichage_data.actif);
      }
    }
  }, [parametres]);

  const createMarkup = (html) => {
    return { __html: html };
  };

  const toggleModal = (information = null) => {
    if (information) {
      setSelectedInformation(information);
    }
    setModalOpen(!isModalOpen);
  };



  const graphEmpData = {
    labels: datas?.compte_employe_par_mois?.map(data => data.mois),
    datasets: [
      {
        label: 'Employés',
        data: datas?.compte_employe_par_mois?.map(data => data.nombre),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
      {
        label: 'Employeurs',
        data: datas?.compte_employeur_par_mois?.map(data => data.nombre),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
      }
    ]
  };

  const graphContratData = {
    labels: datas?.contrats_signes_par_mois?.map(data => data.mois),
    datasets: [
      {
        label: 'Contrats signés',
        data: datas?.contrats_signes_par_mois?.map(data => data.nombre),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      }
    ]
  };

  return (
    <div className="informations-container">
      <Helmet>
        <title>Accueil</title>
        <meta name="description" content="Accueil" />
        <meta name="keywords" content="services, coode-nt, domisimpli, googe play store, app store" />
      </Helmet>
      {loading && <p>Chargement...</p>}
      {error && <p>Erreur : {error}</p>}

      {!loadingData && !errorData && datas && (
        affichageData && (
          <div className="data-container">
            <div className="graph-container">
              <Line data={graphEmpData} />
            </div>
            <div className="graph-container">
              <Line data={graphContratData} />
            </div>
            <div className="counter-data-container">
              <div className="counters-container">
                <DataCounter title="Employés" count={datas.compte_employe_count} />
                <DataCounter title="Employeurs" count={datas.compte_employeur_count} />
              </div>
              <div className="counters-container">
                <DataCounter title="Contrats signés" count={datas.contrat_count} />
                <DataCounter title="Déclarations effectues" count={datas.declaration_count} />
              </div>
            </div>
          </div>
        ))}
      {
        informations && informations.length > 0 && informations
          .filter((info) => info.display)

          .map((information) => (
            <div key={information._id} className="information-card">
              <h3>{information.titre}</h3>
              <img src={information.image} className="information-image" onClick={() => toggleModal(information)} />
              <div className="information-text">
                {/* Utilisation de dangerouslySetInnerHTML pour injecter le HTML */}
                <p className='information-description' dangerouslySetInnerHTML={createMarkup(information.description)}></p>
              </div>
            </div>
          ))
      }
      {isModalOpen && (
        <div className="modal" onClick={() => toggleModal({})} style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}>
          <img
            src={selectedInformation.image}
            alt=""
            style={{
              maxHeight: '100%',
              maxWidth: '100%',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
            }}
          />
        </div>
      )}
    </div >
  );
}

export default Accueil;
