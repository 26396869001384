import {
    DATA_LIST_REQUEST,
    DATA_LIST_SUCCESS,
    DATA_LIST_FAIL,
} from '../constants/dataConstants';

import axios from 'axios';

export const dataListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: DATA_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/data/get_website_data/`,
            config
        )

        dispatch({
            type: DATA_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: DATA_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}