import {
    PARAMETRE_LIST_REQUEST,
    PARAMETRE_LIST_SUCCESS,
    PARAMETRE_LIST_FAIL,

    PARAMETRE_ADD_REQUEST,
    PARAMETRE_ADD_SUCCESS,
    PARAMETRE_ADD_FAIL,
    PARAMETRE_ADD_RESET,

    PARAMETRE_DELETE_REQUEST,
    PARAMETRE_DELETE_SUCCESS,
    PARAMETRE_DELETE_FAIL,
    PARAMETRE_DELETE_RESET,

    PARAMETRE_UPDATE_REQUEST,
    PARAMETRE_UPDATE_SUCCESS,
    PARAMETRE_UPDATE_FAIL,
    PARAMETRE_UPDATE_RESET
} from '../constants/parametresConstants';

import axios from 'axios';

export const parametreListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: PARAMETRE_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/parametres/get_parametres/`,
            config
        )

        dispatch({
            type: PARAMETRE_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PARAMETRE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const parametreAddAction = (parametre) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PARAMETRE_ADD_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/parametres/add_parametres/`,
            parametre,
            config
        )
        
        dispatch({
            type: PARAMETRE_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PARAMETRE_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const parametreDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PARAMETRE_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(
            `/api/parametres/delete_parametres/${id}/`,
            config
        )

        dispatch({
            type: PARAMETRE_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PARAMETRE_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const parametreUpdateAction = (parametre_id, parametre) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PARAMETRE_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,

            },
        }
        
        const { data } = await axios.put(
            `/api/parametres/update_parametres/${parametre_id}/`,
            parametre,
            config
        )

        dispatch({
            type: PARAMETRE_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PARAMETRE_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const parametreAddResetAction = () => (dispatch) => {
    dispatch({ type: PARAMETRE_ADD_RESET });
}

export const parametreDeleteResetAction = () => (dispatch) => {
    dispatch({ type: PARAMETRE_DELETE_RESET });
}

export const parametreUpdateResetAction = () => (dispatch) => {
    dispatch({ type: PARAMETRE_UPDATE_RESET });
}