import {
    MAIL_LIST_REQUEST,
    MAIL_LIST_SUCCESS,
    MAIL_LIST_FAIL,

    MAIL_COUNT_REQUEST,
    MAIL_COUNT_SUCCESS,
    MAIL_COUNT_FAIL,

    MAIL_ADD_REQUEST,
    MAIL_ADD_SUCCESS,
    MAIL_ADD_FAIL,
    MAIL_ADD_RESET,

    MAIL_DELETE_REQUEST,
    MAIL_DELETE_SUCCESS,
    MAIL_DELETE_FAIL,
    MAIL_DELETE_RESET
} from '../constants/mailConstants';

// Compare this snippet from user-interface/src/redux/reducers/mailReducers.js:
export const mailListReducer = (state = { mails: {} }, action) => {
    switch (action.type) {
        case MAIL_LIST_REQUEST:
            return { loading: true, mails: {} };
        case MAIL_LIST_SUCCESS:
            return { loading: false, mails: action.payload };
        case MAIL_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const mailCountReducer = (state = { mailCount: {} }, action) => {
    switch (action.type) {
        case MAIL_COUNT_REQUEST:
            return { loading: true, mailCount: {} };
        case MAIL_COUNT_SUCCESS:
            return { loading: false, mailCount: action.payload };
        case MAIL_COUNT_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/mailReducers.js:
export const mailAddReducer = (state = {}, action) => {
    switch (action.type) {
        case MAIL_ADD_REQUEST:
            return { loading: true };
        case MAIL_ADD_SUCCESS:
            return { loading: false, success: true, mail: action.payload };
        case MAIL_ADD_FAIL:
            return { loading: false, error: action.payload };
        case MAIL_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/mailReducers.js:
export const mailDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case MAIL_DELETE_REQUEST:
            return { loading: true };
        case MAIL_DELETE_SUCCESS:
            return { loading: false, success: true };
        case MAIL_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case MAIL_DELETE_RESET:
            return {};
        default:
            return state;
    }
}
