import {
    REPONSE_LIST_REQUEST,
    REPONSE_LIST_SUCCESS,
    REPONSE_LIST_FAIL,

    REPONSE_ADD_REQUEST,
    REPONSE_ADD_SUCCESS,
    REPONSE_ADD_FAIL,
    REPONSE_ADD_RESET,

    REPONSE_DELETE_REQUEST,
    REPONSE_DELETE_SUCCESS,
    REPONSE_DELETE_FAIL,
    REPONSE_DELETE_RESET,

    REPONSE_UPDATE_REQUEST,
    REPONSE_UPDATE_SUCCESS,
    REPONSE_UPDATE_FAIL,
    REPONSE_UPDATE_RESET
} from '../constants/reponseConstants';

// Compare this snippet from user-interface/src/redux/reducers/reponseReducers.js:
export const reponseListReducer = (state = { reponses: [] }, action) => {
    switch (action.type) {
        case REPONSE_LIST_REQUEST:
            return { loading: true, reponses: [] };
        case REPONSE_LIST_SUCCESS:
            return { loading: false, reponses: action.payload };
        case REPONSE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/reponseReducers.js:
export const reponseAddReducer = (state = {}, action) => {
    switch (action.type) {
        case REPONSE_ADD_REQUEST:
            return { loading: true };
        case REPONSE_ADD_SUCCESS:
            return { loading: false, success: true, reponse: action.payload };
        case REPONSE_ADD_FAIL:
            return { loading: false, error: action.payload };
        case REPONSE_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/reponseReducers.js:
export const reponseDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case REPONSE_DELETE_REQUEST:
            return { loading: true };
        case REPONSE_DELETE_SUCCESS:
            return { loading: false, success: true };
        case REPONSE_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case REPONSE_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/reponseReducers.js:
export const reponseUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case REPONSE_UPDATE_REQUEST:
            return { loading: true };
        case REPONSE_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case REPONSE_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case REPONSE_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}
