import {
    VIDEO_LIST_REQUEST,
    VIDEO_LIST_SUCCESS,
    VIDEO_LIST_FAIL,

    VIDEO_ADD_REQUEST,
    VIDEO_ADD_SUCCESS,
    VIDEO_ADD_FAIL,
    VIDEO_ADD_RESET,

    VIDEO_DELETE_REQUEST,
    VIDEO_DELETE_SUCCESS,
    VIDEO_DELETE_FAIL,
    VIDEO_DELETE_RESET,

    VIDEO_UPDATE_REQUEST,
    VIDEO_UPDATE_SUCCESS,
    VIDEO_UPDATE_FAIL,
    VIDEO_UPDATE_RESET
} from '../constants/videoConstants';

import axios from 'axios';

export const videoListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: VIDEO_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/video/get_videos/`,
            config
        )

        dispatch({
            type: VIDEO_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: VIDEO_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const videoAddAction = (formData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: VIDEO_ADD_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }

        const { data } = await axios.post(
            `/api/video/add_video/`,
            formData,
            config
        )

        dispatch({
            type: VIDEO_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: VIDEO_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const videoDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: VIDEO_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(
            `/api/video/delete_video/${id}/`,
            config
        )

        dispatch({
            type: VIDEO_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: VIDEO_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const videoUpdateAction = (video_id, formData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: VIDEO_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        
        const { data } = await axios.put(
            `/api/video/update_video/${video_id}/`,
            formData,
            config
        )

        dispatch({
            type: VIDEO_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: VIDEO_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const videoAddResetAction = () => (dispatch) => {
    dispatch({ type: VIDEO_ADD_RESET });
}

export const videoDeleteResetAction = () => (dispatch) => {
    dispatch({ type: VIDEO_DELETE_RESET });
}

export const videoUpdateResetAction = () => (dispatch) => {
    dispatch({ type: VIDEO_UPDATE_RESET });
}