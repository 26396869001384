// Dans FAQ.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FAQItem from '../../../components/FAQItem/FAQItem';

import { questionListAction, questionAddAction, questionAddResetAction } from '../../../redux/actions/questionActions';
import { reponseListAction } from '../../../redux/actions/reponseActions';

import { parametreListAction } from '../../../redux/actions/parametresActions';

import './FAQ.css';
import Modale from "../../../components/Modale/Modale";

function FAQ() {
  let dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [modaleState, setModaleState] = useState({ isOpen: false, type: '', message: '' });

  const questionList = useSelector((state) => state.questionList);
  const { questions } = questionList;

  const questionAdd = useSelector((state) => state.questionAdd);
  const {success: questionAddSuccess, error: errorAddSuccess } = questionAdd
  
  const reponseList = useSelector((state) => state.reponseList);
  const { reponses } = reponseList;

  const [showSubmitForm, setShowSubmitForm] = useState(false);
  const [questionTitle, setQuestionTitle] = useState('');
  const [questionText, setQuestionText] = useState('');

  const successAddQestionMessage = "Votre question a été soumise avec succès";
  const errorAddQuestionMessage = "Une erreur s'est produite lors de la soumission de votre question";


  useEffect(() => {
    dispatch(questionListAction());
    dispatch(reponseListAction());
  }, []);

  useEffect(() => {
    if (questionAddSuccess) {
      dispatch(questionListAction());
      dispatch(questionAddResetAction());
      setModaleState({ isOpen: true, type: 'add-success', message: successAddQestionMessage });
    }
    else if (errorAddSuccess) {
      console.log(errorAddSuccess);
      setModaleState({ isOpen: true, type: 'add-error', message: errorAddQuestionMessage });
    }
    dispatch(questionAddResetAction());
  }, []);

  const filteredQuestions = searchTerm.length === 0
    ? (questions || []).filter((q) => q.display)
    : (questions || []).filter((q) =>
        q.titre.toLowerCase().includes(searchTerm.toLowerCase()) && q.display
    );

  const handleSubmitQuestion = (e) => {
    e.preventDefault();
    // Ici, vous pouvez dispatcher une action pour créer une nouvelle question
    // ou faire une requête API pour enregistrer la question dans votre base de données
    console.log('Titre:', questionTitle, 'Texte:', questionText);
    dispatch(questionAddAction(questionTitle, questionText));

    // Réinitialiser le formulaire et cacher le formulaire de soumission
    setQuestionTitle('');
    setQuestionText('');
    setShowSubmitForm(false);
  };

  const handleCloseModale = () => {
    setModaleState({ ...modaleState, isOpen: false });
  };

  return (
    <div className="faq-container">
      <h2>Forum aux questions</h2>
      <button onClick={() => setShowSubmitForm(!showSubmitForm)}>{showSubmitForm ? "Fermer" : "Soumettre une question"}</button>
      {showSubmitForm && (
        <form onSubmit={handleSubmitQuestion} className="submit-question-form">
          <input
            type="text"
            placeholder="Titre de votre question"
            value={questionTitle}
            onChange={(e) => setQuestionTitle(e.target.value)}
          />
          <textarea
            placeholder="Votre question"
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
          ></textarea>
          <button type="submit">Envoyer</button>
        </form>
      )}
      <input
        type="text"
        placeholder="Rechercher..."
        className="faq-search"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {filteredQuestions.map((question) => (
        <FAQItem
          key={question.id}
          question={question}
          reponses={reponses.filter((r) => r.question === question.id)}
        />
      ))}
      <Modale
        isOpen={modaleState.isOpen}
        onClose={handleCloseModale}
        title={modaleState.type === 'add-successs' ? 'Succès' : 'Erreur'}
        type={modaleState.type} // Passez le type ici
      >
        <p>{modaleState.message}</p>
      </Modale>
    </div>
  );
}

export default FAQ;
