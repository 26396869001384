import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';

import RichTextEditor from '../RichTextEditor/RichTextEditor'; // Assurez-vous que le chemin d'accès est correct

import {
    tutorielIndexAddAction,
    tutorielIndexUpdateAction,
    tutorielIndexListAction,
    tutorielIndexDeleteAction
} from '../../redux/actions/tutorielIndexActions';

import {
    tutorielUpdateAction,
    tutorielIndexUpdateResetAction,
} from '../../redux/actions/tutorielActions';

import './IndexEditor.css'; // Assurez-vous que le chemin d'accès est correct

function IndexEditor({ indexs, tutoriels, onSelect }) {
    const dispatch = useDispatch();

    const [newIndexName, setNewIndexName] = useState('');
    const [selectedParentId, setSelectedParentId] = useState(null);
    const [selectedTuto, setSelectedTuto] = useState(null); // [1]
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [videoFile, setVideoFile] = useState(null);

    const [videoTitre, setVideoTitre] = useState('');
    const [description, setDescription] = useState('');


    const onDragEnd = (result) => {
        if (!result.destination) return; // Si l'élément n'est pas déplacé vers une destination, ne rien faire

        const { source, destination } = result;

        // Cas où l'élément est réordonné au sein de la même liste
        if (destination.droppableId === source.droppableId) {
            // Réordonner les index au sein de la même liste (parent ou enfant)
            const items = Array.from(selectedParentId ? indexs.filter(index => index.parent === selectedParentId) : indexs.filter(index => !index.parent));
            const [reorderedItem] = items.splice(source.index, 1);
            items.splice(destination.index, 0, reorderedItem);
            let itemsToUpdate = items.find(item => item.ordre == source.index + 1);

            // Mettre à jour l'état avec la nouvelle liste réordonnée
            // Vous devrez adapter cette partie pour mettre à jour votre état global ou local
            console.log(source.index, destination.index);
            let updatedItem = {
                ...itemsToUpdate,
                ordre: destination.index + 1,
            }

            dispatch(tutorielIndexUpdateAction(updatedItem.id, updatedItem)); // Exemple de mise à jour de l'état
        } else if (destination.droppableId.startsWith('childOf-')) {
            // Cas où l'élément est déplacé pour devenir l'enfant d'un autre index
            const parentId = destination.droppableId.replace('childOf-', '');
            console.log(`Déplacer l'index pour être enfant de ${parentId}`);
            // Adapter cette partie pour créer une relation parent-enfant dans votre état
            // dispatch(tutorielIndexUpdateAction({ id: source.draggableId, parent: parentId })); // Exemple
        } else if (destination.droppableId === "parentDroppable" && selectedParentId) {
            // Cas où un élément enfant est déplacé pour devenir un parent (ou réordonné parmi les parents)
            console.log(`Déplacer l'index pour devenir un parent ou réordonner parmi les parents`);
            // Adapter cette partie pour gérer le déplacement d'un enfant vers les parents
            // dispatch(tutorielIndexUpdateAction({ id: source.draggableId, parent: null })); // Exemple
        }
    };


    const handleCreateIndex = () => {
        if (selectedParentId) {
            // Ajouter comme enfant du parent sélectionné
            console.log('selectedEnfantId', selectedParentId);
            let niveauIndexParent = indexs.filter(index => index.id == selectedParentId)[0].niveau + 1;
            let newIndex = indexs.filter(index => index.parent == selectedParentId && index.niveau == niveauIndexParent).length + 1;
            dispatch(tutorielIndexAddAction({ nom: newIndexName, parent: selectedParentId, ordre: newIndex, niveau: niveauIndexParent }));
        } else {
            // Ajouter comme nouvel index parent
            console.log('selectedParentId', selectedParentId);
            let newIndex = indexs.filter(index => index.parent == null).length + 1;
            dispatch(tutorielIndexAddAction({ nom: newIndexName, ordre: newIndex, niveau: 1 }));
        }
        setNewIndexName('');
    };

    const handleSelectParent = async (parentId) => {
        setSelectedParentId(parentId);
        setSelectedTuto(indexs.filter(index => index.id == parentId)[0].tutoriel);
        // Supposons que vous avez une fonction API `fetchVideosForIndex(parentId)` qui retourne les vidéos pour cet index
        const video = indexs.filter(index => index.id == parentId)[0].tutoriel;
        console.log('video', video);
        setSelectedVideos(video);
    };

    const handleCloseChildrenView = () => {
        setSelectedParentId(null);
    };

    const handleVideoUpload = (event) => {
        setVideoFile(event.target.files[0]);
    };

    const submitVideo = async () => {
        console.log('videoFile', videoFile);
        if (!videoFile) return;
        // Supposons que vous avez une fonction API `uploadVideoForIndex(videoFile, selectedParentId)` pour uploader la vidéo
        // await uploadVideoForIndex(videoFile, selectedParentId);
        // Recharger les vidéos pour l'index après l'upload

        let tuto = tutoriels.filter(tuto => tuto.id == selectedTuto)[0];
        console.log('tuto', tuto);
        console.log('tutoriels', tutoriels);

        let tutoriel_to_update = {
            ...tuto,
            titre: videoTitre,
            description: description,
            video: videoFile
        }

        dispatch(tutorielUpdateAction(selectedTuto, tutoriel_to_update));
    };

    const handleEditorChange = (value) => {
        setDescription(value);
    }


    // Filtrer les indexs pour afficher soit les parents soit les enfants du parent sélectionné
    const displayedIndexs = selectedParentId ? indexs.filter(index => index.parent === selectedParentId) : indexs.filter(index => !index.parent);

    return (
        <div className="tutoriel-indexeditor">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={selectedParentId ? `childOf-${selectedParentId}` : "parentDroppable"} type="INDEX">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} className="index-list">
                            {displayedIndexs
                                .sort((a, b) => a.ordre - b.ordre)
                                .map((index, i) => (
                                    <Draggable key={index.id} draggableId={String(index.id)} index={i}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="index-item"
                                                onClick={() => handleSelectParent(index.id)}
                                            >
                                                {index.nom}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {selectedParentId && (
                <button className="close-children-view" onClick={handleCloseChildrenView}>Fermer la liste des enfants</button>
            )}
            <div className="create-index">
                <input
                    type="text"
                    placeholder="Nom du nouvel index"
                    value={newIndexName}
                    onChange={(e) => setNewIndexName(e.target.value)}
                />
                <button onClick={handleCreateIndex}>Créer</button>
            </div>
            <div className="video-list">
                {selectedVideos != undefined && selectedVideos.length > 0 && selectedVideos.map((video, index) => (
                    <div key={index} className="video-item">
                        {video.nom} {/* Remplacez `video.nom` par la propriété appropriée de votre objet vidéo */}
                    </div>
                ))}
            </div>
            {
                selectedParentId && (
                    <div className="video-upload-form">
                        <input
                            className="video-titre"
                            placeholder="Titre"
                            value={videoTitre}
                            onChange={(e) => setVideoTitre(e.target.value)}
                        />
                        <div className='text-editor'>
                            <RichTextEditor value={description != undefined ? description : ''} onChange={handleEditorChange} />
                        </div>
                    </div>
                )
            }
            {selectedParentId && (
                <>
                    <h3>Uploader une nouvelle vidéo pour {selectedParentId}</h3>
                    <input type="file" onChange={handleVideoUpload} />
                    <button onClick={submitVideo}>Uploader</button>
                </>
            )}
        </div>
    );
}

export default IndexEditor;
