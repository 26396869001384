import {
    CGU_LIST_REQUEST,
    CGU_LIST_SUCCESS,
    CGU_LIST_FAIL,

    CGU_ADD_REQUEST,
    CGU_ADD_SUCCESS,
    CGU_ADD_FAIL,
    CGU_ADD_RESET,

    CGU_DELETE_REQUEST,
    CGU_DELETE_SUCCESS,
    CGU_DELETE_FAIL,
    CGU_DELETE_RESET,

    CGU_UPDATE_REQUEST,
    CGU_UPDATE_SUCCESS,
    CGU_UPDATE_FAIL,
    CGU_UPDATE_RESET
} from '../constants/cguConstants';

// Compare this snippet from user-interface/src/redux/reducers/cguReducers.js:
export const cguListReducer = (state = { cgus: {} }, action) => {
    switch (action.type) {
        case CGU_LIST_REQUEST:
            return { loading: true, cgus: {} };
        case CGU_LIST_SUCCESS:
            return { loading: false, cgus: action.payload };
        case CGU_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/cguReducers.js:
export const cguAddReducer = (state = {}, action) => {
    switch (action.type) {
        case CGU_ADD_REQUEST:
            return { loading: true };
        case CGU_ADD_SUCCESS:
            return { loading: false, success: true, cgu: action.payload };
        case CGU_ADD_FAIL:
            return { loading: false, error: action.payload };
        case CGU_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/cguReducers.js:
export const cguDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CGU_DELETE_REQUEST:
            return { loading: true };
        case CGU_DELETE_SUCCESS:
            return { loading: false, success: true };
        case CGU_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case CGU_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/cguReducers.js:
export const cguUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CGU_UPDATE_REQUEST:
            return { loading: true };
        case CGU_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case CGU_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case CGU_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}
