import {
    MAIL_TO_SEND_LIST_REQUEST,
    MAIL_TO_SEND_LIST_SUCCESS,
    MAIL_TO_SEND_LIST_FAIL,

    MAIL_TO_SEND_ADD_REQUEST,
    MAIL_TO_SEND_ADD_SUCCESS,
    MAIL_TO_SEND_ADD_FAIL,
    MAIL_TO_SEND_ADD_RESET,

    MAIL_TO_SEND_DELETE_REQUEST,
    MAIL_TO_SEND_DELETE_SUCCESS,
    MAIL_TO_SEND_DELETE_FAIL,
    MAIL_TO_SEND_DELETE_RESET,

    SEND_MAIL_REQUEST,
    SEND_MAIL_SUCCESS,
    SEND_MAIL_FAIL,
    SEND_MAIL_RESET
} from '../constants/mailToSendConstants';

// Compare this snippet from user-interface/src/redux/reducers/mailToSendReducers.js:
export const mailToSendListReducer = (state = { mailToSends: {} }, action) => {
    switch (action.type) {
        case MAIL_TO_SEND_LIST_REQUEST:
            return { loading: true, mailToSends: {} };
        case MAIL_TO_SEND_LIST_SUCCESS:
            return { loading: false, mailToSends: action.payload };
        case MAIL_TO_SEND_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/mailToSendReducers.js:
export const mailToSendAddReducer = (state = {}, action) => {
    switch (action.type) {
        case MAIL_TO_SEND_ADD_REQUEST:
            return { loading: true };
        case MAIL_TO_SEND_ADD_SUCCESS:
            return { loading: false, success: true, mailToSend: action.payload };
        case MAIL_TO_SEND_ADD_FAIL:
            return { loading: false, error: action.payload };
        case MAIL_TO_SEND_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/mailToSendReducers.js:
export const mailToSendDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case MAIL_TO_SEND_DELETE_REQUEST:
            return { loading: true };
        case MAIL_TO_SEND_DELETE_SUCCESS:
            return { loading: false, success: true };
        case MAIL_TO_SEND_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case MAIL_TO_SEND_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

export const sendMailReducer = (state = {}, action) => {
    switch (action.type) {
        case SEND_MAIL_REQUEST:
            return { loading: true };
        case SEND_MAIL_SUCCESS:
            return { loading: false, success: true };
        case SEND_MAIL_FAIL:
            return { loading: false, error: action.payload };
        case SEND_MAIL_RESET:
            return {};
        default:
            return state;
    }
}


