import {
    PRIVACY_POLICYS_LIST_REQUEST,
    PRIVACY_POLICYS_LIST_SUCCESS,
    PRIVACY_POLICYS_LIST_FAIL,

    PRIVACY_POLICYS_ADD_REQUEST,
    PRIVACY_POLICYS_ADD_SUCCESS,
    PRIVACY_POLICYS_ADD_FAIL,
    PRIVACY_POLICYS_ADD_RESET,

    PRIVACY_POLICYS_DELETE_REQUEST,
    PRIVACY_POLICYS_DELETE_SUCCESS,
    PRIVACY_POLICYS_DELETE_FAIL,
    PRIVACY_POLICYS_DELETE_RESET,

    PRIVACY_POLICYS_UPDATE_REQUEST,
    PRIVACY_POLICYS_UPDATE_SUCCESS,
    PRIVACY_POLICYS_UPDATE_FAIL,
    PRIVACY_POLICYS_UPDATE_RESET
} from '../constants/privacyPolicysConstants';

// Compare this snippet from user-interface/src/redux/reducers/privacyPolicyReducers.js:
export const privacyPolicyListReducer = (state = { privacyPolicys: {} }, action) => {
    switch (action.type) {
        case PRIVACY_POLICYS_LIST_REQUEST:
            return { loading: true, privacyPolicys: {} };
        case PRIVACY_POLICYS_LIST_SUCCESS:
            return { loading: false, privacyPolicys: action.payload };
        case PRIVACY_POLICYS_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/privacyPolicyReducers.js:
export const privacyPolicyAddReducer = (state = {}, action) => {
    switch (action.type) {
        case PRIVACY_POLICYS_ADD_REQUEST:
            return { loading: true };
        case PRIVACY_POLICYS_ADD_SUCCESS:
            return { loading: false, success: true, privacyPolicy: action.payload };
        case PRIVACY_POLICYS_ADD_FAIL:
            return { loading: false, error: action.payload };
        case PRIVACY_POLICYS_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/privacyPolicyReducers.js:
export const privacyPolicyDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PRIVACY_POLICYS_DELETE_REQUEST:
            return { loading: true };
        case PRIVACY_POLICYS_DELETE_SUCCESS:
            return { loading: false, success: true };
        case PRIVACY_POLICYS_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case PRIVACY_POLICYS_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/privacyPolicyReducers.js:
export const privacyPolicyUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case PRIVACY_POLICYS_UPDATE_REQUEST:
            return { loading: true };
        case PRIVACY_POLICYS_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case PRIVACY_POLICYS_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case PRIVACY_POLICYS_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}
