import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown, Col, Row, ListGroup, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { parametreListAction } from '../../redux/actions/parametresActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import { LinkContainer } from 'react-router-bootstrap'
import { logout } from '../../redux/actions/userActions'

import logo from '../../assets/domisimpli_logomark.png'
import './Header.css'

function Header() {
  const location = useLocation();
  const dispatch = useDispatch()
  let navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const parametreList = useSelector((state) => state.parametreList);
  const { parametres } = parametreList;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeMenu, setActiveMenu] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(false);

  const [affichageTutos, setAffichageTutos] = useState();
  const [affichageFaq, setAffichageFaq] = useState();

  useEffect(() => {
    dispatch(parametreListAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(parametreListAction());

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const submenuParam = queryParams.get('submenu');
    setActiveSubMenu(submenuParam === 'open');
  }, [location]);

  useEffect(() => {
    if (parametres && parametres.length > 0) {
      let affichage_tutos = parametres.find(param => param.nom === 'affichage_tuto');
      let affichage_faq = parametres.find(param => param.nom === 'affichage_faq');
      if (affichage_tutos) {
        setAffichageTutos(affichage_tutos.actif);
      }
      if (affichage_faq) {
        setAffichageFaq(affichage_faq.actif);
      }
    }
  }, [parametres]);


  const handleNavDropdownToggle = (e) => {
    // Sur les appareils tactiles, toggle le sous-menu sans prévenir le comportement par défaut
    if (!('ontouchstart' in window)) {
      e.preventDefault();
    }
    toggleSubMenu();
  };

  // Utilisez onMouseEnter uniquement pour les appareils non tactiles
  const handleMouseEnter = () => {
    if (!('ontouchstart' in window)) {
      setActiveMenu(true);
      setActiveSubMenu(true);
    }
  };

  // Utilisez onMouseLeave uniquement pour les appareils non tactiles
  const handleMouseLeave = () => {
    if (!('ontouchstart' in window)) {
      setActiveSubMenu(false);
      setTimeout(() => {
        if (!activeSubMenu) {
          setActiveMenu(false);
        }
      }, 200);
    }
  };


  const logoutHandler = () => {
    dispatch(logout());
    navigate('/');
  };

  // Fonction pour basculer l'affichage du sous-menu
  const toggleSubMenu = () => {
    if (isMobile) {
      setActiveSubMenu(!activeSubMenu);
    }
  };

  const handleNavDropdownClick = (e) => {
    // Empêche l'action par défaut pour les clics sur le sous-menu
    e.preventDefault();
  
    // Basculer l'état du sous-menu uniquement sur les appareils mobiles
    if (isMobile) {
      setActiveSubMenu(!activeSubMenu);
    }
  };

  return (
    <>
      <header className={`header`}>
        <img
          src={logo} // Assurez-vous que le chemin est correct
          className="header-logo"
          alt="Logo"
        />
        <Navbar className='navbar' variant="dark">
          <Col xs={12} sm={12} md={12} lg={6} className="header-links-col">
            <Container className='header-pages-link'>
              <LinkContainer className='header-page-link' to='/accueil'>
                <Nav.Link className="nav-item">{t('Accueil')}</Nav.Link>
              </LinkContainer>
              <LinkContainer className='header-page-link' to='/domisimpli'>
                <Nav.Link className="nav-item">{t('Découvrir DomiSimpli')}</Nav.Link>
              </LinkContainer>
              {
                affichageTutos && (
                  <LinkContainer className='header-page-link' to='/tutoriels'>
                    <Nav.Link className="nav-item">{t('Tutoriels')}</Nav.Link>
                  </LinkContainer>
                )
              }
              <LinkContainer className='header-page-link' to='/contact'>
                <Nav.Link className="nav-item">{t('Contact')}</Nav.Link>
              </LinkContainer>
              <LinkContainer
                className='header-page-link'
                to="/faq"
                onClick={handleNavDropdownToggle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Nav.Link
                  className="nav-item"
                  onClick={handleNavDropdownClick}
                >
                  {t('Divers')}
                  {activeSubMenu && (
                    <div className="second-level-dropdown">
                      {
                        affichageFaq && (
                          <LinkContainer to="/faq">
                            <NavDropdown.Item className="nav-subitem">{t('FAQ')}</NavDropdown.Item>
                          </LinkContainer>
                        )
                      }
                      <LinkContainer to="/privacy-policy">
                        <NavDropdown.Item className="nav-subitem">{t('Politique de confidentialité')}</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/cgu">
                        <NavDropdown.Item className="nav-subitem">{t('CGU')}</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/cgv">
                        <NavDropdown.Item className="nav-subitem">{t('CGV')}</NavDropdown.Item>
                      </LinkContainer>
                    </div>
                  )
                  }
                </Nav.Link>
              </LinkContainer>
              {
                userInfo && userInfo.isAdmin && (
                  <LinkContainer className='header-page-link' to="/administration">
                    <Nav.Link className="nav-item">{t('Administration')}</Nav.Link>
                  </LinkContainer>
                )
              }
              <Container className='header-pages-link'>
                {
                  userInfo && userInfo != undefined && userInfo.isAdmin ? (
                    <Nav.Link onClick={logoutHandler} className="nav-item">
                      <FontAwesomeIcon icon={faSignOutAlt} /> {/* Icône de déconnexion */}
                    </Nav.Link>
                  ) : (
                    <LinkContainer to="/connexion">
                      <Nav.Link className="nav-item">
                        <FontAwesomeIcon icon={faSignInAlt} /> {/* Icône de connexion */}
                      </Nav.Link>
                    </LinkContainer>
                  )
                }
              </Container>
            </Container>
          </Col>
        </Navbar>
      </header >
    </>
  )
}

export default Header