export const CGV_LIST_REQUEST = 'CGV_LIST_REQUEST';
export const CGV_LIST_SUCCESS = 'CGV_LIST_SUCCESS';
export const CGV_LIST_FAIL = 'CGV_LIST_FAIL';

export const CGV_ADD_REQUEST = 'CGV_ADD_REQUEST';
export const CGV_ADD_SUCCESS = 'CGV_ADD_SUCCESS';
export const CGV_ADD_FAIL = 'CGV_ADD_FAIL';
export const CGV_ADD_RESET = 'CGV_ADD_RESET';

export const CGV_DELETE_REQUEST = 'CGV_DELETE_REQUEST';
export const CGV_DELETE_SUCCESS = 'CGV_DELETE_SUCCESS';
export const CGV_DELETE_FAIL = 'CGV_DELETE_FAIL';
export const CGV_DELETE_RESET = 'CGV_DELETE_RESET';

export const CGV_UPDATE_REQUEST = 'CGV_UPDATE_REQUEST';
export const CGV_UPDATE_SUCCESS = 'CGV_UPDATE_SUCCESS';
export const CGV_UPDATE_FAIL = 'CGV_UPDATE_FAIL';
export const CGV_UPDATE_RESET = 'CGV_UPDATE_RESET';
