import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Document, Page, pdfjs } from 'react-pdf';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { privacyPolicyListAction } from '../../../redux/actions/privacyPolicysActions';
import '../PDF.css';

// Configuration du worker PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PrivacyPolicy() {
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);

  const privacyPolicyList = useSelector((state) => state.privacyPolicyList);
  const { loading, error, privacyPolicys } = privacyPolicyList;

  useEffect(() => {
    dispatch(privacyPolicyListAction());
  }, [dispatch]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const zoomIn = () => {
    setScale(scale + 0.2);
  };

  const zoomOut = () => {
    if (scale > 0.2) setScale(scale - 0.2);
  };

  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1);
  };


  return (
    <div className="cg-container">
      <Helmet>
        <title>PrivacyPolicy</title>
      </Helmet>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {privacyPolicys && (
        <>
          <div className="pdf-controls">
            <button className="pdfbutton" onClick={zoomOut}>-</button>
            <button className="pdfbutton" onClick={zoomIn}>+</button>
            <button className="pdfbutton" disabled={pageNumber <= 1} onClick={goToPrevPage}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
            <button className="pdfbutton" disabled={pageNumber >= numPages} onClick={goToNextPage}>
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
            <p>Page {pageNumber} sur {numPages}</p>
          </div>
          <div className="pdf-viewer">
            <Document
              file={privacyPolicys.file}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} scale={scale} />
            </Document>
          </div>
        </>
      )}

    </div>
  );
}

export default PrivacyPolicy;
