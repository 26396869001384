import {
    MAIL_LIST_REQUEST,
    MAIL_LIST_SUCCESS,
    MAIL_LIST_FAIL,

    MAIL_COUNT_REQUEST,
    MAIL_COUNT_SUCCESS,
    MAIL_COUNT_FAIL,

    MAIL_ADD_REQUEST,
    MAIL_ADD_SUCCESS,
    MAIL_ADD_FAIL,
    MAIL_ADD_RESET,

    MAIL_DELETE_REQUEST,
    MAIL_DELETE_SUCCESS,
    MAIL_DELETE_FAIL,
    MAIL_DELETE_RESET,
} from '../constants/mailConstants';

import axios from 'axios';

export const mailListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: MAIL_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/mail/get_mails/`,
            config
        )

        dispatch({
            type: MAIL_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: MAIL_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const mailCountAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: MAIL_COUNT_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/mail/get_mails_count/`,
            config
        )

        dispatch({
            type: MAIL_COUNT_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: MAIL_COUNT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const mailAddAction = (mail) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MAIL_ADD_REQUEST,
        })

        let formData = new FormData();
        // Ajoutez chaque champ de l'objet candidature au formData
        for (const key in mail) {
            if (mail.hasOwnProperty(key)) {
                formData.append(key, mail[key]);
            }
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.post(
            `/api/mail/add_mail/`,
            mail,
            config
        )

        dispatch({
            type: MAIL_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: MAIL_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const mailDeleteAction = (email) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MAIL_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.post(
            `/api/mail/delete_mail/`,
            email,
            config
        )

        dispatch({
            type: MAIL_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: MAIL_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}


export const mailAddResetAction = () => (dispatch) => {
    dispatch({ type: MAIL_ADD_RESET });
}

export const mailDeleteResetAction = () => (dispatch) => {
    dispatch({ type: MAIL_DELETE_RESET });
}