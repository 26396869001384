export const PARAMETRE_LIST_REQUEST = 'PARAMETRE_LIST_REQUEST';
export const PARAMETRE_LIST_SUCCESS = 'PARAMETRE_LIST_SUCCESS';
export const PARAMETRE_LIST_FAIL = 'PARAMETRE_LIST_FAIL';

export const PARAMETRE_ADD_REQUEST = 'PARAMETRE_ADD_REQUEST';
export const PARAMETRE_ADD_SUCCESS = 'PARAMETRE_ADD_SUCCESS';
export const PARAMETRE_ADD_FAIL = 'PARAMETRE_ADD_FAIL';
export const PARAMETRE_ADD_RESET = 'PARAMETRE_ADD_RESET';

export const PARAMETRE_DELETE_REQUEST = 'PARAMETRE_DELETE_REQUEST';
export const PARAMETRE_DELETE_SUCCESS = 'PARAMETRE_DELETE_SUCCESS';
export const PARAMETRE_DELETE_FAIL = 'PARAMETRE_DELETE_FAIL';
export const PARAMETRE_DELETE_RESET = 'PARAMETRE_DELETE_RESET';

export const PARAMETRE_UPDATE_REQUEST = 'PARAMETRE_UPDATE_REQUEST';
export const PARAMETRE_UPDATE_SUCCESS = 'PARAMETRE_UPDATE_SUCCESS';
export const PARAMETRE_UPDATE_FAIL = 'PARAMETRE_UPDATE_FAIL';
export const PARAMETRE_UPDATE_RESET = 'PARAMETRE_UPDATE_RESET';
