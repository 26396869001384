// store.js
import { configureStore } from '@reduxjs/toolkit'
import {thunk} from 'redux-thunk';
import { rootReducer } from './reducers/appReducer';


// const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

// Dans votre fichier store.js ou un fichier similaire
const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

const initialState = {
  user: { userInfo: userInfoFromStorage }, // Assurez-vous que cela correspond à la structure de votre état global
};

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    // Pas besoin d'utiliser composeWithDevTools, Redux Toolkit le gère automatiquement en mode développement
  });

export default store;
