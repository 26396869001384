import {
    PARAMETRE_LIST_REQUEST,
    PARAMETRE_LIST_SUCCESS,
    PARAMETRE_LIST_FAIL,

    PARAMETRE_ADD_REQUEST,
    PARAMETRE_ADD_SUCCESS,
    PARAMETRE_ADD_FAIL,
    PARAMETRE_ADD_RESET,

    PARAMETRE_DELETE_REQUEST,
    PARAMETRE_DELETE_SUCCESS,
    PARAMETRE_DELETE_FAIL,
    PARAMETRE_DELETE_RESET,

    PARAMETRE_UPDATE_REQUEST,
    PARAMETRE_UPDATE_SUCCESS,
    PARAMETRE_UPDATE_FAIL,
    PARAMETRE_UPDATE_RESET
} from '../constants/parametresConstants';

// Compare this snippet from user-interface/src/redux/reducers/parametreReducers.js:
export const parametreListReducer = (state = { parametres: [] }, action) => {
    switch (action.type) {
        case PARAMETRE_LIST_REQUEST:
            return { loading: true, parametres: [] };
        case PARAMETRE_LIST_SUCCESS:
            return { loading: false, parametres: action.payload };
        case PARAMETRE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/parametreReducers.js:
export const parametreAddReducer = (state = {}, action) => {
    switch (action.type) {
        case PARAMETRE_ADD_REQUEST:
            return { loading: true };
        case PARAMETRE_ADD_SUCCESS:
            return { loading: false, success: true, parametre: action.payload };
        case PARAMETRE_ADD_FAIL:
            return { loading: false, error: action.payload };
        case PARAMETRE_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/parametreReducers.js:
export const parametreDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PARAMETRE_DELETE_REQUEST:
            return { loading: true };
        case PARAMETRE_DELETE_SUCCESS:
            return { loading: false, success: true };
        case PARAMETRE_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case PARAMETRE_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/parametreReducers.js:
export const parametreUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case PARAMETRE_UPDATE_REQUEST:
            return { loading: true };
        case PARAMETRE_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case PARAMETRE_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case PARAMETRE_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}
