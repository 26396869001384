export const MAIL_TO_SEND_LIST_REQUEST = 'MAIL_TO_SEND_LIST_REQUEST';
export const MAIL_TO_SEND_LIST_SUCCESS = 'MAIL_TO_SEND_LIST_SUCCESS';
export const MAIL_TO_SEND_LIST_FAIL = 'MAIL_TO_SEND_LIST_FAIL';

export const MAIL_TO_SEND_ADD_REQUEST = 'MAIL_TO_SEND_ADD_REQUEST';
export const MAIL_TO_SEND_ADD_SUCCESS = 'MAIL_TO_SEND_ADD_SUCCESS';
export const MAIL_TO_SEND_ADD_FAIL = 'MAIL_TO_SEND_ADD_FAIL';
export const MAIL_TO_SEND_ADD_RESET = 'MAIL_TO_SEND_ADD_RESET';

export const MAIL_TO_SEND_DELETE_REQUEST = 'MAIL_TO_SEND_DELETE_REQUEST';
export const MAIL_TO_SEND_DELETE_SUCCESS = 'MAIL_TO_SEND_DELETE_SUCCESS';
export const MAIL_TO_SEND_DELETE_FAIL = 'MAIL_TO_SEND_DELETE_FAIL';
export const MAIL_TO_SEND_DELETE_RESET = 'MAIL_TO_SEND_DELETE_RESET';

export const SEND_MAIL_REQUEST = 'SEND_MAIL_REQUEST';
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS';
export const SEND_MAIL_FAIL = 'SEND_MAIL_FAIL';
export const SEND_MAIL_RESET = 'SEND_MAIL_RESET';