import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { videoListAction } from '../../redux/actions/videoActions';
import { pubListAction } from '../../redux/actions/pubActions';
import { mailAddAction, mailDeleteAction, mailAddResetAction, mailDeleteResetAction } from '../../redux/actions/mailActions';

import { parametreListAction } from '../../redux/actions/parametresActions';

import HtmlContent from '../../components/HtmlContent/HtmlContent';

import Modale from '../../components/Modale/Modale';

import './Domisimpli.css';

function Domisimpli() {
    const dispatch = useDispatch();

    const videoList = useSelector((state) => state.videoList);
    const { loading, error, videos } = videoList;

    const pubList = useSelector((state) => state.pubList);
    const { pubs, loading: pubLoading, error: pubError } = pubList;

    const mailAdd = useSelector((state) => state.mailAdd);
    const { loading: mailAddLoading, error: mailAddError, success: mailAddSuccess } = mailAdd;

    const mailDelete = useSelector((state) => state.mailDelete);
    const { loading: mailDeleteLoading, error: mailDeleteError, success: mailDeleteSuccess } = mailDelete;

    const parametreList = useSelector((state) => state.parametreList);
    const { parametres } = parametreList;

    const [videoDomiSimpli, setVideoDomiSimpli] = useState(null);

    const [modaleState, setModaleState] = useState({ isOpen: false, type: '', message: '' });

    let successAddMessage = 'Inscription réussie ! Vous recevrez bientôt les dernières nouvelles sur DomiSimpli.';
    let errorAddMessage = 'Une erreur s\'est produite lors de l\'inscription. Veuillez réessayer.';

    let successDeleteMessage = 'Désinscription réussie ! Vous ne recevrez plus les dernières nouvelles sur DomiSimpli.';
    let errorDeleteMessage = 'Une erreur s\'est produite lors de la désinscription. Veuillez réessayer.';

    const [affichageVideo, setAffichageVideo] = useState();
    const [affichagePubs, setAffichagePubs] = useState();

    useEffect(() => {
        if (mailAddSuccess) {
            // Afficher une modale de succès
            setModaleState({ isOpen: true, type: 'add-success', message: successAddMessage });
        }
        else if (mailAddError) {
            // Afficher une modale d'erreur
            setModaleState({ isOpen: true, type: 'add-error', message: errorAddMessage });
        }
        dispatch(mailAddResetAction());
        dispatch(parametreListAction());
        setEmail('');
    }, [mailAddSuccess, mailAddError]);

    useEffect(() => {
        if (mailDeleteSuccess) {
            // Afficher une modale de succès
            setModaleState({ isOpen: true, type: 'add-success', message: successDeleteMessage });
        }
        else if (mailDeleteError) {
            // Afficher une modale d'erreur
            setModaleState({ isOpen: true, type: 'add-error', message: errorDeleteMessage });
        }
        dispatch(mailDeleteResetAction());
        setEmail('');
    }, [mailDeleteSuccess, mailDeleteError]);

    useEffect(() => {
        dispatch(videoListAction());
        dispatch(pubListAction());
    }, [dispatch]);

    useEffect(() => {
        if (parametres && parametres.length > 0) {
            let affichage_video = parametres.find(param => param.nom === 'affichage_video');
            if (affichage_video) {
                setAffichageVideo(affichage_video.actif);
            }
            let affichage_pubs = parametres.find(param => param.nom === 'affichage_pubs');
            if (affichage_pubs) {
                setAffichagePubs(affichage_pubs.actif);
            }
        }
    }, [parametres]);

    useEffect(() => {
        if (videos && videos.length > 0) {
            const foundVideo = videos.find(video => video.titre === "DomiSimpli");
            setVideoDomiSimpli(foundVideo);
        }
    }, [videos]);

    const [email, setEmail] = useState('');

    const handleNewsletterSignup = (e) => {
        e.preventDefault();
        console.log(email);
        dispatch(mailAddAction({ email }));
    }

    const handleUnsubscribe = () => {
        console.log(email);
        dispatch(mailDeleteAction({ email }));
    }

    const handleCloseModale = () => {
        setModaleState({ isOpen: false, type: '', message: '' });
    }

    return (
        <div className="domisimpli-container">
            <Helmet>
                <title>Accueil</title>
                <meta name="description" content="Accueil" />
                <meta name="keywords" content="services, coode-nt, domisimpli, google play store, app store" />
            </Helmet>
            <div className="newsletter-signup">
                <h2>Inscrivez-vous pour recevoir les dernières nouvelles sur DomiSimpli</h2>
                <form onSubmit={handleNewsletterSignup} className="newsletter-form">
                    <div className="form-control">
                        <input
                            type="email"
                            placeholder="Votre email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button type="submit">S'inscrire</button>
                        <button onClick={handleUnsubscribe} className="unsubscribe">Se désinscrire</button>
                    </div>
                </form>
            </div>

            {loading && <p>Chargement...</p>}
            {error && <p>Erreur : {error}</p>}
            {affichageVideo && videoDomiSimpli && (
                <div key={videoDomiSimpli.id} className="video-card">
                    <div className="video-content">
                        {
                            videoDomiSimpli.display && (
                                <div className="video-container">
                                    <video controls>
                                        <source src={videoDomiSimpli.video} type="video/mp4" />
                                        Désolé, votre navigateur ne prend pas en charge les vidéos intégrées.
                                    </video>
                                </div>
                            )
                        }
                    </div>
                    <div className="video-text">
                        <HtmlContent html={videoDomiSimpli.description} />
                    </div>
                </div>
            )}
            {!pubLoading && !pubError && pubs && affichagePubs && pubs.map((pub) => (
                pub.display && (
                    <div key={pub.id} className="pub-card">
                        <div className="pub-text">
                            <h2>{pub.titre}</h2>
                            <p>{pub.description}</p>
                        </div>
                        <div className="pub-media">
                            {
                                pub.image && (
                                    <img src={pub.image} alt={pub.titre} className="pub-image" />
                                )
                            }
                            <a href={pub.url} target="_blank" rel="noopener noreferrer" className="pub-button">Voir l'article</a>
                        </div>
                    </div>
                )
            ))}
            <Modale
                isOpen={modaleState.isOpen}
                onClose={handleCloseModale}
                title={modaleState.type === 'add-success' ? 'Succès' : 'Erreur'}
                type={modaleState.type} // Passez le type ici
            >
                <p>{modaleState.message}</p>
            </Modale>
        </div>
    );

}

export default Domisimpli;
