import {
    CGV_LIST_REQUEST,
    CGV_LIST_SUCCESS,
    CGV_LIST_FAIL,

    CGV_ADD_REQUEST,
    CGV_ADD_SUCCESS,
    CGV_ADD_FAIL,
    CGV_ADD_RESET,

    CGV_DELETE_REQUEST,
    CGV_DELETE_SUCCESS,
    CGV_DELETE_FAIL,
    CGV_DELETE_RESET,

    CGV_UPDATE_REQUEST,
    CGV_UPDATE_SUCCESS,
    CGV_UPDATE_FAIL,
    CGV_UPDATE_RESET
} from '../constants/cgvConstants';

// Compare this snippet from user-interface/src/redux/reducers/cgvReducers.js:
export const cgvListReducer = (state = { cgvs: [] }, action) => {
    switch (action.type) {
        case CGV_LIST_REQUEST:
            return { loading: true, cgvs: [] };
        case CGV_LIST_SUCCESS:
            return { loading: false, cgvs: action.payload };
        case CGV_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/cgvReducers.js:
export const cgvAddReducer = (state = {}, action) => {
    switch (action.type) {
        case CGV_ADD_REQUEST:
            return { loading: true };
        case CGV_ADD_SUCCESS:
            return { loading: false, success: true, cgv: action.payload };
        case CGV_ADD_FAIL:
            return { loading: false, error: action.payload };
        case CGV_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/cgvReducers.js:
export const cgvDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CGV_DELETE_REQUEST:
            return { loading: true };
        case CGV_DELETE_SUCCESS:
            return { loading: false, success: true };
        case CGV_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case CGV_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/cgvReducers.js:
export const cgvUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CGV_UPDATE_REQUEST:
            return { loading: true };
        case CGV_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case CGV_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case CGV_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}
