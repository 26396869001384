export const MAIL_LIST_REQUEST = 'MAIL_LIST_REQUEST';
export const MAIL_LIST_SUCCESS = 'MAIL_LIST_SUCCESS';
export const MAIL_LIST_FAIL = 'MAIL_LIST_FAIL';

export const MAIL_COUNT_REQUEST = 'MAIL_COUNT_REQUEST';
export const MAIL_COUNT_SUCCESS = 'MAIL_COUNT_SUCCESS';
export const MAIL_COUNT_FAIL = 'MAIL_COUNT_FAIL';

export const MAIL_ADD_REQUEST = 'MAIL_ADD_REQUEST';
export const MAIL_ADD_SUCCESS = 'MAIL_ADD_SUCCESS';
export const MAIL_ADD_FAIL = 'MAIL_ADD_FAIL';
export const MAIL_ADD_RESET = 'MAIL_ADD_RESET';

export const MAIL_DELETE_REQUEST = 'MAIL_DELETE_REQUEST';
export const MAIL_DELETE_SUCCESS = 'MAIL_DELETE_SUCCESS';
export const MAIL_DELETE_FAIL = 'MAIL_DELETE_FAIL';
export const MAIL_DELETE_RESET = 'MAIL_DELETE_RESET';