export const TUTORIEL_INDEX_LIST_REQUEST = 'TUTORIEL_INDEX_LIST_REQUEST';
export const TUTORIEL_INDEX_LIST_SUCCESS = 'TUTORIEL_INDEX_LIST_SUCCESS';
export const TUTORIEL_INDEX_LIST_FAIL = 'TUTORIEL_INDEX_LIST_FAIL';

export const TUTORIEL_INDEX_ADD_REQUEST = 'TUTORIEL_INDEX_ADD_REQUEST';
export const TUTORIEL_INDEX_ADD_SUCCESS = 'TUTORIEL_INDEX_ADD_SUCCESS';
export const TUTORIEL_INDEX_ADD_FAIL = 'TUTORIEL_INDEX_ADD_FAIL';
export const TUTORIEL_INDEX_ADD_RESET = 'TUTORIEL_INDEX_ADD_RESET';

export const TUTORIEL_INDEX_DELETE_REQUEST = 'TUTORIEL_INDEX_DELETE_REQUEST';
export const TUTORIEL_INDEX_DELETE_SUCCESS = 'TUTORIEL_INDEX_DELETE_SUCCESS';
export const TUTORIEL_INDEX_DELETE_FAIL = 'TUTORIEL_INDEX_DELETE_FAIL';
export const TUTORIEL_INDEX_DELETE_RESET = 'TUTORIEL_INDEX_DELETE_RESET';

export const TUTORIEL_INDEX_UPDATE_REQUEST = 'TUTORIEL_INDEX_UPDATE_REQUEST';
export const TUTORIEL_INDEX_UPDATE_SUCCESS = 'TUTORIEL_INDEX_UPDATE_SUCCESS';
export const TUTORIEL_INDEX_UPDATE_FAIL = 'TUTORIEL_INDEX_UPDATE_FAIL';
export const TUTORIEL_INDEX_UPDATE_RESET = 'TUTORIEL_INDEX_UPDATE_RESET';
