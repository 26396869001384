import {
    TUTORIEL_LIST_REQUEST,
    TUTORIEL_LIST_SUCCESS,
    TUTORIEL_LIST_FAIL,

    TUTORIEL_ADD_REQUEST,
    TUTORIEL_ADD_SUCCESS,
    TUTORIEL_ADD_FAIL,
    TUTORIEL_ADD_RESET,

    TUTORIEL_DELETE_REQUEST,
    TUTORIEL_DELETE_SUCCESS,
    TUTORIEL_DELETE_FAIL,
    TUTORIEL_DELETE_RESET,

    TUTORIEL_UPDATE_REQUEST,
    TUTORIEL_UPDATE_SUCCESS,
    TUTORIEL_UPDATE_FAIL,
    TUTORIEL_UPDATE_RESET
} from '../constants/tutorielConstants';

import axios from 'axios';

export const tutorielListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: TUTORIEL_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/tutoriel/get_tutoriels/`,
            config
        )

        dispatch({
            type: TUTORIEL_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: TUTORIEL_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const tutorielAddAction = (tutoriel) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TUTORIEL_ADD_REQUEST,
        })

        let formData = new FormData();
        // Ajoutez chaque champ de l'objet candidature au formData
        for (const key in tutoriel) {
            if (tutoriel.hasOwnProperty(key)) {
                formData.append(key, tutoriel[key]);
            }
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }

        const { data } = await axios.post(
            `/api/tutoriel/add_tutoriel/`,
            formData,
            config
        )

        dispatch({
            type: TUTORIEL_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: TUTORIEL_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const tutorielDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TUTORIEL_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(
            `/api/tutoriel/delete_tutoriel/${id}/`,
            config
        )

        dispatch({
            type: TUTORIEL_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: TUTORIEL_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const tutorielUpdateAction = (tutoriel_id, tutoriel) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TUTORIEL_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.put(
            `/api/tutoriel/update_tutoriel/${tutoriel_id}/`,
            tutoriel,
            config
        )     
        
        dispatch({
            type: TUTORIEL_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: TUTORIEL_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const tutorielAddResetAction = () => (dispatch) => {
    dispatch({ type: TUTORIEL_ADD_RESET });
}

export const tutorielDeleteResetAction = () => (dispatch) => {
    dispatch({ type: TUTORIEL_DELETE_RESET });
}

export const tutorielUpdateResetAction = () => (dispatch) => {
    dispatch({ type: TUTORIEL_UPDATE_RESET });
}