export const PRIVACY_POLICYS_LIST_REQUEST = 'PRIVACY_POLICYS_LIST_REQUEST';
export const PRIVACY_POLICYS_LIST_SUCCESS = 'PRIVACY_POLICYS_LIST_SUCCESS';
export const PRIVACY_POLICYS_LIST_FAIL = 'PRIVACY_POLICYS_LIST_FAIL';

export const PRIVACY_POLICYS_ADD_REQUEST = 'PRIVACY_POLICYS_ADD_REQUEST';
export const PRIVACY_POLICYS_ADD_SUCCESS = 'PRIVACY_POLICYS_ADD_SUCCESS';
export const PRIVACY_POLICYS_ADD_FAIL = 'PRIVACY_POLICYS_ADD_FAIL';
export const PRIVACY_POLICYS_ADD_RESET = 'PRIVACY_POLICYS_ADD_RESET';

export const PRIVACY_POLICYS_DELETE_REQUEST = 'PRIVACY_POLICYS_DELETE_REQUEST';
export const PRIVACY_POLICYS_DELETE_SUCCESS = 'PRIVACY_POLICYS_DELETE_SUCCESS';
export const PRIVACY_POLICYS_DELETE_FAIL = 'PRIVACY_POLICYS_DELETE_FAIL';
export const PRIVACY_POLICYS_DELETE_RESET = 'PRIVACY_POLICYS_DELETE_RESET';

export const PRIVACY_POLICYS_UPDATE_REQUEST = 'PRIVACY_POLICYS_UPDATE_REQUEST';
export const PRIVACY_POLICYS_UPDATE_SUCCESS = 'PRIVACY_POLICYS_UPDATE_SUCCESS';
export const PRIVACY_POLICYS_UPDATE_FAIL = 'PRIVACY_POLICYS_UPDATE_FAIL';
export const PRIVACY_POLICYS_UPDATE_RESET = 'PRIVACY_POLICYS_UPDATE_RESET';
