import { combineReducers } from 'redux';

import {
    contactListReducer,
    contactAddReducer,
    contactDeleteReducer,
    contactUpdateReducer
} from './contactReducers';

import {
    informationListReducer,
    informationAddReducer,
    informationDeleteReducer,
    informationUpdateReducer
} from './informationReducers';

import {
    cguAddReducer,
    cguDeleteReducer,
    cguListReducer,
    cguUpdateReducer,
} from './cguReducers';

import {
    cgvAddReducer,
    cgvDeleteReducer,
    cgvListReducer,
    cgvUpdateReducer,
} from './cgvReducers';

import {
    pubAddReducer,
    pubDeleteReducer,
    pubListReducer,
    pubUpdateReducer,
} from './pubReducers';

import {
    questionAddReducer,
    questionDeleteReducer,
    questionListReducer,
    questionUpdateReducer,
} from './questionReducers';

import {
    reponseAddReducer,
    reponseDeleteReducer,
    reponseListReducer,
    reponseUpdateReducer,
} from './reponseReducers';

import {
    tutorielIndexAddReducer,
    tutorielIndexDeleteReducer,
    tutorielIndexListReducer,
    tutorielIndexUpdateReducer,
} from './tutorielIndexReducers';

import {
    tutorielAddReducer,
    tutorielDeleteReducer,
    tutorielListReducer,
    tutorielUpdateReducer,
} from './tutorielReducers';

import {
    videoAddReducer,
    videoDeleteReducer,
    videoListReducer,
    videoUpdateReducer,
} from './videoReducers';

import {
    privacyPolicyListReducer,
    privacyPolicyAddReducer,
    privacyPolicyDeleteReducer,
    privacyPolicyUpdateReducer,
} from './privacyPolicysReducers';

import {
    mailListReducer,
    mailCountReducer,
    mailAddReducer,
    mailDeleteReducer,
} from './mailReducers';

import {
    mailToSendListReducer,
    mailToSendAddReducer,
    mailToSendDeleteReducer,
    sendMailReducer,
} from './mailToSendReducers';

import{
    parametreAddReducer,
    parametreDeleteReducer,
    parametreListReducer,
    parametreUpdateReducer,
} from './parametresReducers';

import userReducer from '../../features/user/userSlice';

import {
    dataListReducer,
} from './dataReducers'


const appReducer = combineReducers({
    contactList: contactListReducer,
    contactAdd: contactAddReducer,
    contactDelete: contactDeleteReducer,
    contactUpdate: contactUpdateReducer,

    informationList: informationListReducer,
    informationAdd: informationAddReducer,
    informationDelete: informationDeleteReducer,
    informationUpdate: informationUpdateReducer,

    cguList: cguListReducer,
    cguAdd: cguAddReducer,
    cguDelete: cguDeleteReducer,
    cguUpdate: cguUpdateReducer,

    cgvList: cgvListReducer,
    cgvAdd: cgvAddReducer,
    cgvDelete: cgvDeleteReducer,
    cgvUpdate: cgvUpdateReducer,

    dataList: dataListReducer,

    mailList: mailListReducer,
    mailCount: mailCountReducer,
    mailAdd: mailAddReducer,
    mailDelete: mailDeleteReducer,

    mailToSendList: mailToSendListReducer,
    mailToSendAdd: mailToSendAddReducer,
    mailToSendDelete: mailToSendDeleteReducer,
    sendMail: sendMailReducer,

    parametreList: parametreListReducer,
    parametreAdd: parametreAddReducer,
    parametreDelete: parametreDeleteReducer,
    parametreUpdate: parametreUpdateReducer,

    privacyPolicyList: privacyPolicyListReducer,
    privacyPolicyAdd: privacyPolicyAddReducer,
    privacyPolicyDelete: privacyPolicyDeleteReducer,
    privacyPolicyUpdate: privacyPolicyUpdateReducer,

    pubList: pubListReducer,
    pubAdd: pubAddReducer,
    pubDelete: pubDeleteReducer,
    pubUpdate: pubUpdateReducer,

    questionList: questionListReducer,
    questionAdd: questionAddReducer,
    questionDelete: questionDeleteReducer,
    questionUpdate: questionUpdateReducer,

    reponseList: reponseListReducer,
    reponseAdd: reponseAddReducer,
    reponseDelete: reponseDeleteReducer,
    reponseUpdate: reponseUpdateReducer,

    tutorielList: tutorielListReducer,
    tutorielAdd: tutorielAddReducer,
    tutorielDelete: tutorielDeleteReducer,
    tutorielUpdate: tutorielUpdateReducer,

    tutorielIndexList: tutorielIndexListReducer,
    tutorielIndexAdd: tutorielIndexAddReducer,
    tutorielIndexDelete: tutorielIndexDeleteReducer,
    tutorielIndexUpdate: tutorielIndexUpdateReducer,

    videoList: videoListReducer,
    videoAdd: videoAddReducer,
    videoDelete: videoDeleteReducer,
    videoUpdate: videoUpdateReducer,

    userLogin: userReducer,
});

export const rootReducer = (state, action) => {
    if (action.type === 'RESET_APP') {
        state = undefined;
    }
    return appReducer(state, action);
};