import {
    QUESTION_LIST_REQUEST,
    QUESTION_LIST_SUCCESS,
    QUESTION_LIST_FAIL,

    QUESTION_ADD_REQUEST,
    QUESTION_ADD_SUCCESS,
    QUESTION_ADD_FAIL,
    QUESTION_ADD_RESET,

    QUESTION_DELETE_REQUEST,
    QUESTION_DELETE_SUCCESS,
    QUESTION_DELETE_FAIL,
    QUESTION_DELETE_RESET,

    QUESTION_UPDATE_REQUEST,
    QUESTION_UPDATE_SUCCESS,
    QUESTION_UPDATE_FAIL,
    QUESTION_UPDATE_RESET
} from '../constants/questionConstants';

// Compare this snippet from user-interface/src/redux/reducers/questionReducers.js:
export const questionListReducer = (state = { questions: [] }, action) => {
    switch (action.type) {
        case QUESTION_LIST_REQUEST:
            return { loading: true, questions: [] };
        case QUESTION_LIST_SUCCESS:
            return { loading: false, questions: action.payload };
        case QUESTION_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/questionReducers.js:
export const questionAddReducer = (state = {}, action) => {
    switch (action.type) {
        case QUESTION_ADD_REQUEST:
            return { loading: true };
        case QUESTION_ADD_SUCCESS:
            return { loading: false, success: true, question: action.payload };
        case QUESTION_ADD_FAIL:
            return { loading: false, error: action.payload };
        case QUESTION_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/questionReducers.js:
export const questionDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case QUESTION_DELETE_REQUEST:
            return { loading: true };
        case QUESTION_DELETE_SUCCESS:
            return { loading: false, success: true };
        case QUESTION_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case QUESTION_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/questionReducers.js:
export const questionUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case QUESTION_UPDATE_REQUEST:
            return { loading: true };
        case QUESTION_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case QUESTION_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case QUESTION_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}
