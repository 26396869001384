import {
    REPONSE_LIST_REQUEST,
    REPONSE_LIST_SUCCESS,
    REPONSE_LIST_FAIL,

    REPONSE_ADD_REQUEST,
    REPONSE_ADD_SUCCESS,
    REPONSE_ADD_FAIL,
    REPONSE_ADD_RESET,

    REPONSE_DELETE_REQUEST,
    REPONSE_DELETE_SUCCESS,
    REPONSE_DELETE_FAIL,
    REPONSE_DELETE_RESET,

    REPONSE_UPDATE_REQUEST,
    REPONSE_UPDATE_SUCCESS,
    REPONSE_UPDATE_FAIL,
    REPONSE_UPDATE_RESET
} from '../constants/reponseConstants';

import axios from 'axios';

export const reponseListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: REPONSE_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/reponse/get_reponses/`,
            config
        )

        dispatch({
            type: REPONSE_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: REPONSE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const reponseAddAction = (reponse) => async (dispatch, getState) => {
    try {
        dispatch({
            type: REPONSE_ADD_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(
            `/api/reponse/add_reponse/`,
            reponse,
            config
        )

        dispatch({
            type: REPONSE_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: REPONSE_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const reponseDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: REPONSE_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(
            `/api/reponse/delete_reponse/${id}/`,
            config
        )

        dispatch({
            type: REPONSE_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: REPONSE_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const reponseUpdateAction = (reponse_id, reponse) => async (dispatch, getState) => {
    try {
        dispatch({
            type: REPONSE_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        
        const { data } = await axios.put(
            `/api/reponse/update_reponse/${reponse_id}/`,
            reponse,
            config
        )

        dispatch({
            type: REPONSE_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: REPONSE_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const reponseAddResetAction = () => (dispatch) => {
    dispatch({ type: REPONSE_ADD_RESET });
}

export const reponseDeleteResetAction = () => (dispatch) => {
    dispatch({ type: REPONSE_DELETE_RESET });
}

export const reponseUpdateResetAction = () => (dispatch) => {
    dispatch({ type: REPONSE_UPDATE_RESET });
}