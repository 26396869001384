import {
    CGU_LIST_REQUEST,
    CGU_LIST_SUCCESS,
    CGU_LIST_FAIL,

    CGU_ADD_REQUEST,
    CGU_ADD_SUCCESS,
    CGU_ADD_FAIL,
    CGU_ADD_RESET,

    CGU_DELETE_REQUEST,
    CGU_DELETE_SUCCESS,
    CGU_DELETE_FAIL,
    CGU_DELETE_RESET,

    CGU_UPDATE_REQUEST,
    CGU_UPDATE_SUCCESS,
    CGU_UPDATE_FAIL,
    CGU_UPDATE_RESET
} from '../constants/cguConstants';

import axios from 'axios';

export const cguListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CGU_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/cgu/get_cgus/`,
            config
        )

        dispatch({
            type: CGU_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CGU_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const cguAddAction = (cgu) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CGU_ADD_REQUEST,
        })

        let formData = new FormData();
        // Ajoutez chaque champ de l'objet candidature au formData
        for (const key in cgu) {
            if (cgu.hasOwnProperty(key)) {
                formData.append(key, cgu[key]);
            }
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }

        const { data } = await axios.post(
            `/api/cgu/add_cgu/`,
            formData,
            config
        )

        dispatch({
            type: CGU_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CGU_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const cguDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CGU_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(
            `/api/cgu/delete_cgu/${id}/`,
            config
        )

        dispatch({
            type: CGU_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CGU_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const cguUpdateAction = (cgu_id, cgu) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CGU_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        
        const { data } = await axios.put(
            `/api/cgu/update_cgu/${cgu_id}/`,
            cgu,
            config
        )

        dispatch({
            type: CGU_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CGU_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const cguAddResetAction = () => (dispatch) => {
    dispatch({ type: CGU_ADD_RESET });
}

export const cguDeleteResetAction = () => (dispatch) => {
    dispatch({ type: CGU_DELETE_RESET });
}

export const cguUpdateResetAction = () => (dispatch) => {
    dispatch({ type: CGU_UPDATE_RESET });
}