import {
    PUB_LIST_REQUEST,
    PUB_LIST_SUCCESS,
    PUB_LIST_FAIL,

    PUB_ADD_REQUEST,
    PUB_ADD_SUCCESS,
    PUB_ADD_FAIL,
    PUB_ADD_RESET,

    PUB_DELETE_REQUEST,
    PUB_DELETE_SUCCESS,
    PUB_DELETE_FAIL,
    PUB_DELETE_RESET,

    PUB_UPDATE_REQUEST,
    PUB_UPDATE_SUCCESS,
    PUB_UPDATE_FAIL,
    PUB_UPDATE_RESET
} from '../constants/pubConstants';

// Compare this snippet from user-interface/src/redux/reducers/pubReducers.js:
export const pubListReducer = (state = { pubs: [] }, action) => {
    switch (action.type) {
        case PUB_LIST_REQUEST:
            return { loading: true, pubs: [] };
        case PUB_LIST_SUCCESS:
            return { loading: false, pubs: action.payload };
        case PUB_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/pubReducers.js:
export const pubAddReducer = (state = {}, action) => {
    switch (action.type) {
        case PUB_ADD_REQUEST:
            return { loading: true };
        case PUB_ADD_SUCCESS:
            return { loading: false, success: true, pub: action.payload };
        case PUB_ADD_FAIL:
            return { loading: false, error: action.payload };
        case PUB_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/pubReducers.js:
export const pubDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PUB_DELETE_REQUEST:
            return { loading: true };
        case PUB_DELETE_SUCCESS:
            return { loading: false, success: true };
        case PUB_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case PUB_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/pubReducers.js:
export const pubUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case PUB_UPDATE_REQUEST:
            return { loading: true };
        case PUB_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case PUB_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case PUB_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}
