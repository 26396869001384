import {
    TUTORIEL_INDEX_LIST_REQUEST,
    TUTORIEL_INDEX_LIST_SUCCESS,
    TUTORIEL_INDEX_LIST_FAIL,

    TUTORIEL_INDEX_ADD_REQUEST,
    TUTORIEL_INDEX_ADD_SUCCESS,
    TUTORIEL_INDEX_ADD_FAIL,
    TUTORIEL_INDEX_ADD_RESET,

    TUTORIEL_INDEX_DELETE_REQUEST,
    TUTORIEL_INDEX_DELETE_SUCCESS,
    TUTORIEL_INDEX_DELETE_FAIL,
    TUTORIEL_INDEX_DELETE_RESET,

    TUTORIEL_INDEX_UPDATE_REQUEST,
    TUTORIEL_INDEX_UPDATE_SUCCESS,
    TUTORIEL_INDEX_UPDATE_FAIL,
    TUTORIEL_INDEX_UPDATE_RESET
} from '../constants/tutorielIndexConstants';

import axios from 'axios';

export const tutorielIndexListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: TUTORIEL_INDEX_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/tutoriel-index/get_tutoriel_indexs/`,
            config
        )

        dispatch({
            type: TUTORIEL_INDEX_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: TUTORIEL_INDEX_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const tutorielIndexAddAction = (tutorielIndex) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TUTORIEL_INDEX_ADD_REQUEST,
        })

        let formData = new FormData();
        // Ajoutez chaque champ de l'objet candidature au formData
        for (const key in tutorielIndex) {
            if (tutorielIndex.hasOwnProperty(key)) {
                formData.append(key, tutorielIndex[key]);
            }
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }

        const { data } = await axios.post(
            `/api/tutoriel-index/add_tutoriel_index/`,
            formData,
            config
        )

        dispatch({
            type: TUTORIEL_INDEX_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: TUTORIEL_INDEX_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const tutorielIndexDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TUTORIEL_INDEX_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(
            `/api/tutoriel-index/delete_tutoriel_index/${id}/`,
            config
        )

        dispatch({
            type: TUTORIEL_INDEX_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: TUTORIEL_INDEX_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const tutorielIndexUpdateAction = (tutoriel_index_id, tutorielIndex) => async (dispatch, getState) => {
    try {
        dispatch({
            type: TUTORIEL_INDEX_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        
        const { data } = await axios.put(
            `/api/tutoriel-index/update_tutoriel_index/${tutoriel_index_id}/`,
            tutorielIndex,
            config
        )

        dispatch({
            type: TUTORIEL_INDEX_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: TUTORIEL_INDEX_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const tutorielIndexAddResetAction = () => (dispatch) => {
    dispatch({ type: TUTORIEL_INDEX_ADD_RESET });
}

export const tutorielIndexDeleteResetAction = () => (dispatch) => {
    dispatch({ type: TUTORIEL_INDEX_DELETE_RESET });
}

export const tutorielIndexUpdateResetAction = () => (dispatch) => {
    dispatch({ type: TUTORIEL_INDEX_UPDATE_RESET });
}