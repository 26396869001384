export const QUESTION_LIST_REQUEST = 'QUESTION_LIST_REQUEST';
export const QUESTION_LIST_SUCCESS = 'QUESTION_LIST_SUCCESS';
export const QUESTION_LIST_FAIL = 'QUESTION_LIST_FAIL';

export const QUESTION_ADD_REQUEST = 'QUESTION_ADD_REQUEST';
export const QUESTION_ADD_SUCCESS = 'QUESTION_ADD_SUCCESS';
export const QUESTION_ADD_FAIL = 'QUESTION_ADD_FAIL';
export const QUESTION_ADD_RESET = 'QUESTION_ADD_RESET';

export const QUESTION_DELETE_REQUEST = 'QUESTION_DELETE_REQUEST';
export const QUESTION_DELETE_SUCCESS = 'QUESTION_DELETE_SUCCESS';
export const QUESTION_DELETE_FAIL = 'QUESTION_DELETE_FAIL';
export const QUESTION_DELETE_RESET = 'QUESTION_DELETE_RESET';

export const QUESTION_UPDATE_REQUEST = 'QUESTION_UPDATE_REQUEST';
export const QUESTION_UPDATE_SUCCESS = 'QUESTION_UPDATE_SUCCESS';
export const QUESTION_UPDATE_FAIL = 'QUESTION_UPDATE_FAIL';
export const QUESTION_UPDATE_RESET = 'QUESTION_UPDATE_RESET';
