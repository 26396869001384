import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { tutorielListAction } from '../../redux/actions/tutorielActions';
import { tutorielIndexListAction } from '../../redux/actions/tutorielIndexActions';

import { parametreListAction } from '../../redux/actions/parametresActions';

import HtmlContent from '../../components/HtmlContent/HtmlContent';

import Index from '../../components/Index/Index';
import './Tutoriel.css';

function Tutoriel() {
  const dispatch = useDispatch();

  const tutorielList = useSelector((state) => state.tutorielList);
  const { loading, error, tutoriels } = tutorielList;

  const tutorielIndexList = useSelector((state) => state.tutorielIndexList);
  const { tutorielIndexs, loading: tutorielIndexLoading, error: tutorielIndexError } = tutorielIndexList;

  const parametreList = useSelector((state) => state.parametreList);
  const { parametres } = parametreList;

  const [selectedTutoriel, setSelectedTutoriel] = useState(null);

  const [affichageTutos, setAffichageTutos] = useState();

  useEffect(() => {
    dispatch(tutorielListAction());
    dispatch(tutorielIndexListAction());
    dispatch(parametreListAction());
  }, [dispatch]);

  const handleIndexClick = (tutorielId) => {
    const tutoriel = tutoriels.find(t => t.id === tutorielId);
    setSelectedTutoriel(tutoriel);
  };

  return (
    <div className="tutoriel-page">
      <Helmet>
        <title>Tutoriels</title>
        <meta name="description" content="Liste des tutoriels" />
      </Helmet>
      <Index className='tutoriel-index' indexs={tutorielIndexs} onSelect={handleIndexClick} />
      <div className="tutoriel-content">
        {selectedTutoriel && (
          <>
            <video controls src={selectedTutoriel.video.video} className="tutoriel-video"></video>
            <div className="tuto-video-titre"><HtmlContent html={selectedTutoriel.video.titre} /></div>
            <div className="video-description"><HtmlContent html={selectedTutoriel.video.description} /></div>
          </>
        )}
      </div>
    </div>
  );
}

export default Tutoriel;
