import {
    DATA_LIST_REQUEST,
    DATA_LIST_SUCCESS,
    DATA_LIST_FAIL,
} from '../constants/dataConstants';

// Compare this snippet from user-interface/src/redux/reducers/dataReducers.js:
export const dataListReducer = (state = { datas: [] }, action) => {
    switch (action.type) {
        case DATA_LIST_REQUEST:
            return { loading: true, datas: [] };
        case DATA_LIST_SUCCESS:
            return { loading: false, datas: action.payload };
        case DATA_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}