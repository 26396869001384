import {
    MAIL_TO_SEND_LIST_REQUEST,
    MAIL_TO_SEND_LIST_SUCCESS,
    MAIL_TO_SEND_LIST_FAIL,

    MAIL_TO_SEND_ADD_REQUEST,
    MAIL_TO_SEND_ADD_SUCCESS,
    MAIL_TO_SEND_ADD_FAIL,
    MAIL_TO_SEND_ADD_RESET,

    MAIL_TO_SEND_DELETE_REQUEST,
    MAIL_TO_SEND_DELETE_SUCCESS,
    MAIL_TO_SEND_DELETE_FAIL,
    MAIL_TO_SEND_DELETE_RESET,

    SEND_MAIL_REQUEST,
    SEND_MAIL_SUCCESS,
    SEND_MAIL_FAIL,
    SEND_MAIL_RESET
} from '../constants/mailToSendConstants';

import axios from 'axios';

export const mailToSendListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: MAIL_TO_SEND_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/mail-to-send/get_mail_to_sends/`,
            config
        )

        dispatch({
            type: MAIL_TO_SEND_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: MAIL_TO_SEND_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const mailToSendAddAction = (subject, body_html, date_envoi) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MAIL_TO_SEND_ADD_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const instance = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        })

        const { data } = await instance.post(
            `/api/mail-to-send/add_mail_to_send/`,
            {subject, body_html, date_envoi}
        )
    console.log(MAIL_TO_SEND_ADD_SUCCESS)

        dispatch({
            type: MAIL_TO_SEND_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: MAIL_TO_SEND_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const mailToSendDeleteAction = (emailToSend) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MAIL_TO_SEND_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.post(
            `/api/mail-to-send/delete_mail_to_send/`,
            emailToSend,
            config
        )

        dispatch({
            type: MAIL_TO_SEND_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: MAIL_TO_SEND_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const sendMailAction = (subject, body_html) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SEND_MAIL_REQUEST,
        })
        
        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.post(
            `/api/mail-to-send/send_email_by_no_reply/`,
            {subject, body_html},
            config
        )
        dispatch({
            type: SEND_MAIL_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SEND_MAIL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}


export const mailToSendAddResetAction = () => (dispatch) => {
    dispatch({ type: MAIL_TO_SEND_ADD_RESET });
}

export const mailToSendDeleteResetAction = () => (dispatch) => {
    dispatch({ type: MAIL_TO_SEND_DELETE_RESET });
}

export const sendMailResetAction = () => (dispatch) => {
    dispatch({ type: SEND_MAIL_RESET });
}