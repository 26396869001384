import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import Toggle from 'react-toggle';
import "react-toggle/style.css"; // pour les styles par défaut

import Modale from '../../components/Modale/Modale';

import {
    informationListAction,
    informationAddAction,
    informationUpdateAction,
    informationDeleteAction,
    informationAddResetAction,
    informationUpdateResetAction,
    informationDeleteResetAction
} from '../../redux/actions/informationActions';

import { videoListAction, videoUpdateAction, videoUpdateResetAction } from '../../redux/actions/videoActions';
import {
    pubListAction,
    pubAddAction,
    pubDeleteAction,
    pubUpdateAction,
    pubAddResetAction,
    pubDeleteResetAction,
    pubUpdateResetAction
} from '../../redux/actions/pubActions';
import { tutorielListAction } from '../../redux/actions/tutorielActions';
import {
    tutorielIndexListAction,
    tutorielIndexAddResetAction,
    tutorielIndexDeleteResetAction,
    tutorielIndexUpdateResetAction
} from '../../redux/actions/tutorielIndexActions';
import {
    parametreListAction,
    parametreAddAction,
    parametreDeleteAction,
    parametreUpdateAction,
    parametreAddResetAction,
    parametreUpdateResetAction,
} from '../../redux/actions/parametresActions';

import {
    sendMailAction,
    mailToSendAddAction,
    mailToSendDeleteAction,
    mailToSendListAction,
    mailToSendAddResetAction,
    sendMailResetAction
} from '../../redux/actions/mailToSendActions';

import{
    mailCountAction,
} from '../../redux/actions/mailActions';

import {
    questionListAction,
    questionUpdateAction,
    questionDeleteAction,
    questionUpdateResetAction,
    questionDeleteResetAction
} from '../../redux/actions/questionActions';
import { reponseAddAction } from "../../redux/actions/reponseActions";

import {
    contactListAction,
    contactUpdateAction,
    contactDeleteAction,

    contactUpdateResetAction,
    contactDeleteResetAction
} from '../../redux/actions/contactActions';

import {
    tutorielUpdateResetAction,
} from '../../redux/actions/tutorielActions';


import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';
import IndexEditor from '../../components/IndexEditor/IndexEditor';

import './Administration.css';

function Administration() {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState('accueil');
    const [modaleState, setModaleState] = useState({ isOpen: false, type: '', message: '' });

    const informationList = useSelector((state) => state.informationList);
    const { informations, loading, error } = informationList;

    const videoList = useSelector((state) => state.videoList);
    const { loading: videoLoading, error: videoError, videos } = videoList;

    const videoAdd = useSelector((state) => state.videoAdd);
    const { loading: videoAddLoading, error: videoAddError, success: videoAddSuccess } = videoAdd;

    const videoUpdate = useSelector((state) => state.videoUpdate);
    const { loading: videoUpdateLoading, error: videoUpdateError, success: videoUpdateSuccess } = videoUpdate;

    const pubList = useSelector((state) => state.pubList);
    const { pubs, loading: pubLoading, error: pubError } = pubList;

    const tutorielList = useSelector((state) => state.tutorielList);
    const { loading: loadingTutoriel, error: errorTutoriel, tutoriels } = tutorielList;

    const tutorielIndexList = useSelector((state) => state.tutorielIndexList);
    const { tutorielIndexs, loading: tutorielIndexLoading, error: tutorielIndexError } = tutorielIndexList;

    const tutorielIndexAdd = useSelector((state) => state.tutorielIndexAdd);
    const { loading: tutorielIndexAddLoading, error: tutorielIndexAddError, success: tutorielIndexAddSuccess } = tutorielIndexAdd;

    const tutorielIndexDelete = useSelector((state) => state.tutorielIndexDelete);
    const { loading: tutorielIndexDeleteLoading, error: tutorielIndexDeleteError, success: tutorielIndexDeleteSuccess } = tutorielIndexDelete;

    const tutorielIndexUpdate = useSelector((state) => state.tutorielIndexUpdate);
    const { loading: tutorielIndexUpdateLoading, error: tutorielIndexUpdateError, success: tutorielIndexUpdateSuccess } = tutorielIndexUpdate;

    const tutorielUpdate = useSelector((state) => state.tutorielUpdate);
    const { loading: tutorielUpdateLoading, error: tutorielUpdateError, success: tutorielUpdateSuccess } = tutorielUpdate;

    const questionList = useSelector((state) => state.questionList);
    const { questions } = questionList;

    const questionUpdate = useSelector((state) => state.questionUpdate);
    const { loading: questionUpdateLoading, error: questionUpdateError, success: questionUpdateSuccess } = questionUpdate;

    const questionDelete = useSelector((state) => state.questionDelete);
    const { loading: questionDeleteLoading, error: questionDeleteError, success: questionDeleteSuccess } = questionDelete;

    const mailToSendAdd = useSelector((state) => state.mailToSendAdd);
    const { loading: mailToSendAddLoading, error: mailToSendAddError, success: mailToSendAddSuccess } = mailToSendAdd;

    const sendMail = useSelector((state) => state.sendMail);
    const { loading: sendMailLoading, error: sendMailError, success: sendMailSuccess } = sendMail;

    const parametreList = useSelector((state) => state.parametreList);
    const { parametres, loading: parametreLoading, error: parametreError, success: parametreSuccess } = parametreList;

    const parametreAdd = useSelector((state) => state.parametreAdd);
    const { loading: parametreAddLoading, error: parametreAddError, success: parametreAddSuccess } = parametreAdd;

    const parametreDelete = useSelector((state) => state.parametreDelete);
    const {
        loading: parametreDeleteLoading,
        error: parametreDeleteError,
        success: parametreDeleteSuccess
    } = parametreDelete;

    const parametreUpdate = useSelector((state) => state.parametreUpdate);
    const {
        loading: parametreUpdateLoading,
        error: parametreUpdateError,
        success: parametreUpdateSuccess
    } = parametreUpdate;

    const informationAdd = useSelector((state) => state.informationAdd);
    const { loading: informationAddLoading, error: informationAddError, success: informationAddSuccess } = informationAdd;

    const informationDelete = useSelector((state) => state.informationDelete);
    const {
        loading: informationDeleteLoading,
        error: informationDeleteError,
        success: informationDeleteSuccess
    } = informationDelete;

    const informationUpdate = useSelector((state) => state.informationUpdate);
    const {
        loading: informationUpdateLoading,
        error: informationUpdateError,
        success: informationUpdateSuccess
    } = informationUpdate;

    const pubAdd = useSelector((state) => state.pubAdd);
    const { loading: pubAddLoading, error: pubAddError, success: pubAddSuccess } = pubAdd;

    const pubDelete = useSelector((state) => state.pubDelete);
    const { loading: pubDeleteLoading, error: pubDeleteError, success: pubDeleteSuccess } = pubDelete;

    const pubUpdate = useSelector((state) => state.pubUpdate);
    const { loading: pubUpdateLoading, error: pubUpdateError, success: pubUpdateSuccess } = pubUpdate;

    const contactList = useSelector((state) => state.contactList);
    const { contacts, loading: contactLoading, error: contactError } = contactList;

    const contactUpdate = useSelector((state) => state.contactUpdate);
    const { loading: contactUpdateLoading, error: contactUpdateError, success: contactUpdateSuccess } = contactUpdate;

    const contactDelete = useSelector((state) => state.contactDelete);
    const { loading: contactDeleteLoading, error: contactDeleteError, success: contactDeleteSuccess } = contactDelete;

    const mailCount = useSelector((state) => state.mailCount);
    const { mailCount: mailCountData, loading: mailCountLoading, error: mailCountError } = mailCount;

    const addSuccessMessage = 'L\'information a été ajoutée avec succès!';
    const addErrorMessage = 'Une erreur s\'est produite lors de l\'ajout de l\'information. Veuillez réessayer.';
    const updateSuccessMessage = 'L\'information a été mise à jour avec succès!';
    const updateErrorMessage = 'Une erreur s\'est produite lors de la mise à jour de l\'information. Veuillez réessayer.';
    const paramUpdateSuccesMessage = 'Le paramètre a été mis à jour avec succès!';
    const paramUpdateErrorMessage = 'Une erreur s\'est produite lors de la mise à jour du paramètre. Veuillez réessayer.';
    const deleteSuccessMessage = 'L\'information a été supprimée avec succès!';
    const deleteErrorMessage = 'Une erreur s\'est produite lors de la suppression de l\'information. Veuillez réessayer.';
    const addPubSuccessMessage = 'La publicité a été ajoutée avec succès!';
    const addPubErrorMessage = 'Une erreur s\'est produite lors de l\'ajout de la publicité. Veuillez réessayer.';
    const updatePubSuccessMessage = 'La publicité a été mise à jour avec succès!';
    const updatePubErrorMessage = 'Une erreur s\'est produite lors de la mise à jour de la publicité. Veuillez réessayer.';
    const deletePubSuccessMessage = 'La publicité a été supprimée avec succès!';
    const deletePubErrorMessage = 'Une erreur s\'est produite lors de la suppression de la publicité. Veuillez réessayer.';
    const sendMailSuccessMessage = 'L\'email a été envoyé avec succès!';
    const sendMailErrorMessage = 'Une erreur s\'est produite lors de l\'envoi de l\'email. Veuillez réessayer.';
    const scheduleMailSuccessMessage = 'L\'email a été programmé avec succès!';
    const scheduleMailErrorMessage = 'Une erreur s\'est produite lors de la programmation de l\'email. Veuillez réessayer.';
    const addDomiSimpliVideoSuccessMessage = 'La vidéo DomiSimpli a été ajoutée avec succès!';
    const addDomiSimpliVideoErrorMessage = 'Une erreur s\'est produite lors de l\'ajout de la vidéo DomiSimpli. Veuillez réessayer.';
    const updateDomiSimpliVideoSuccessMessage = 'La vidéo DomiSimpli a été mise à jour avec succès!';
    const updateDomiSimpliVideoErrorMessage = 'Une erreur s\'est produite lors de la mise à jour de la vidéo DomiSimpli. Veuillez réessayer.';
    const addTutorielIndexSuccessMessage = 'L\'index a été ajouté avec succès!';
    const addTutorielIndexErrorMessage = 'Une erreur s\'est produite lors de l\'ajout de l\'index. Veuillez réessayer.';
    const deleteTutorielIndexSuccessMessage = 'L\'index a été supprimé avec succès!';
    const deleteTutorielIndexErrorMessage = 'Une erreur s\'est produite lors de la suppression de l\'index. Veuillez réessayer.';
    const updateTutorielIndexSuccessMessage = 'L\'index a été mis à jour avec succès!';
    const updateTutorielIndexErrorMessage = 'Une erreur s\'est produite lors de la mise à jour de l\'index. Veuillez réessayer.';
    const addQuestionSuccessMessage = 'La question a été ajoutée avec succès!';
    const addQuestionErrorMessage = 'Une erreur s\'est produite lors de l\'ajout de la question. Veuillez réessayer.';
    const updateQuestionSuccessMessage = 'La question a été mise à jour avec succès!';
    const updateQuestionErrorMessage = 'Une erreur s\'est produite lors de la mise à jour de la question. Veuillez réessayer.';
    const deleteQuestionSuccessMessage = 'La question a été supprimée avec succès!';
    const deleteQuestionErrorMessage = 'Une erreur s\'est produite lors de la suppression de la question. Veuillez réessayer.';
    const tutorielIndexAddSuccessMessage = 'L\'index a été ajouté avec succès!';
    const tutorielIndexAddErrorMessage = 'Une erreur s\'est produite lors de l\'ajout de l\'index. Veuillez réessayer.';
    const tutorielIndexUpdateSuccessMessage = 'Le tutoriel a été mis à jour avec succès!';
    const tutorielIndexUpdateErrorMessage = 'Une erreur s\'est produite lors de la mise à jour du tutoriel. Veuillez réessayer.';

    const [titre, setTitre] = useState('');
    const [texte, setTexte] = useState('');
    const [image, setImage] = useState(null);
    const [affichageForm, setAffichageForm] = useState(false);
    const [affichageInfo, setAffichageInfo] = useState(false);
    const [edit, setEdit] = useState(false);
    const [idEdit, setIdEdit] = useState(0);
    const [informationImage, setInformationImage] = useState('');

    const [affichageData, setAffichageData] = useState(false);
    const [affichagePubs, setAffichagePubs] = useState(false);
    const [affichageTutoriel, setAffichageTutoriel] = useState(false);
    const [affichageFaq, setAffichageFaq] = useState(false);

    const [emailSubject, setEmailSubject] = useState('');
    const [emailContent, setEmailContent] = useState('');
    const [sendDate, setSendDate] = useState('');
    const [sendTime, setSendTime] = useState('');

    // Nouveaux états pour la gestion des publicités
    const [publicites, setPublicites] = useState([]);
    const [pubEdit, setPubEdit] = useState(false);
    const [pubIdEdit, setPubIdEdit] = useState(null);
    const [pubTitre, setPubTitre] = useState('');
    const [pubDescription, setPubDescription] = useState('');
    const [pubImage, setPubImage] = useState(null);
    const [pubUrl, setPubUrl] = useState('');

    const [videoId, setVideoId] = useState(null);
    const [titreDomiSimpli, setTitreDomiSimpli] = useState('');
    const [descriptionDomiSimpli, setDescriptionDomiSimpli] = useState('');
    const [videoDomiSimpli, setVideoDomiSimpli] = useState(null);
    const [affichageVideo, setAffichageVideo] = useState(false);

    const [showAnswerForm, setShowAnswerForm] = useState(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);
    const [reponseTexte, setReponseTexte] = useState('');

    const toggleShowResponded = () => setShowResponded(!showResponded);
    const [showResponded, setShowResponded] = useState(false);

    const [mailProgram, setMailProgram] = useState(false);


    useEffect(() => {
        dispatch(informationListAction());
        dispatch(videoListAction());
        dispatch(pubListAction());
        dispatch(tutorielListAction());
        dispatch(tutorielIndexListAction());
        dispatch(parametreListAction());
        dispatch(questionListAction());
        dispatch(contactListAction());
        dispatch(mailCountAction());
    }, []);

    useEffect(() => {
        if (parametreUpdateSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: paramUpdateSuccesMessage });
            dispatch(parametreListAction());
        } else if (parametreUpdateError) {
            setModaleState({ isOpen: true, type: 'add-error', message: paramUpdateErrorMessage });
        }
        dispatch(parametreUpdateResetAction())
    }, [parametreUpdateSuccess, parametreUpdateError]);

    useEffect(() => {
        if (parametreAddSuccess)
            dispatch(parametreListAction());
        else if (parametreAddError)
            console.log(parametreAddError);
        dispatch(parametreAddResetAction())
    }, [parametreAddSuccess, parametreAddError]);

    useEffect(() => {
        if (informationAddSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: addSuccessMessage });
            dispatch(informationListAction());
            resetDisplayForm();
        } else if (informationAddError) {
            setModaleState({ isOpen: true, type: 'add-error', message: addErrorMessage });
        }
        dispatch(informationAddResetAction())
    }, [informationAddSuccess, informationAddError]);

    useEffect(() => {
        if (informationDeleteSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: deleteSuccessMessage });
            dispatch(informationListAction());
            resetDisplayForm();
        } else if (informationDeleteError) {
            setModaleState({ isOpen: true, type: 'add-error', message: deleteErrorMessage });
        }
        dispatch(informationDeleteResetAction())
    }, [informationDeleteSuccess, informationDeleteError]);

    useEffect(() => {
        if (informationUpdateSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: updateSuccessMessage });
            dispatch(informationListAction());
            resetDisplayForm();
        } else if (informationUpdateError)
            console.log(informationUpdateError);
        dispatch(informationUpdateResetAction())
    }, [informationUpdateSuccess, informationUpdateError]);

    useEffect(() => {
        if (pubAddSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: addPubSuccessMessage });
            dispatch(pubListAction());
            resetPubForm();
        } else if (pubAddError) {
            setModaleState({ isOpen: true, type: 'add-error', message: addPubErrorMessage });
        }
        dispatch(pubAddResetAction());
    }, [pubAddSuccess, pubAddError]);

    useEffect(() => {
        if (pubUpdateSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: updatePubSuccessMessage });
            dispatch(pubListAction());
            resetPubForm();
        } else if (pubUpdateError) {
            setModaleState({ isOpen: true, type: 'add-error', message: updatePubErrorMessage });
        }
        dispatch(pubUpdateResetAction());
    }, [pubUpdateSuccess, pubUpdateError]);

    useEffect(() => {
        if (videoUpdateSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: updateDomiSimpliVideoSuccessMessage });
            dispatch(videoListAction());
        }
        else if (videoUpdateError) {
            setModaleState({ isOpen: true, type: 'add-error', message: updateDomiSimpliVideoErrorMessage });
        }
        dispatch(videoUpdateResetAction());
    }, [videoUpdateSuccess, videoUpdateError]);

    useEffect(() => {
        if (tutorielIndexAddSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: addTutorielIndexSuccessMessage });
            dispatch(tutorielIndexListAction());
        } else if (tutorielIndexAddError) {
            setModaleState({ isOpen: true, type: 'add-error', message: addTutorielIndexErrorMessage });
        }
        dispatch(tutorielIndexAddResetAction());
    }, [tutorielIndexAddSuccess, tutorielIndexAddError]);

    useEffect(() => {
        if (tutorielIndexDeleteSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: deleteTutorielIndexSuccessMessage });
            dispatch(tutorielIndexListAction());
        } else if (tutorielIndexDeleteError) {
            setModaleState({ isOpen: true, type: 'add-error', message: deleteTutorielIndexErrorMessage });
        }
        dispatch(tutorielIndexDeleteResetAction());
    }, [tutorielIndexDeleteSuccess, tutorielIndexDeleteError]);

    useEffect(() => {
        if (tutorielIndexUpdateSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: updateTutorielIndexSuccessMessage });
            dispatch(tutorielIndexListAction());
        } else if (tutorielIndexUpdateError) {
            setModaleState({ isOpen: true, type: 'add-error', message: updateTutorielIndexErrorMessage });
        }
        dispatch(tutorielIndexUpdateResetAction());
    }, [tutorielIndexUpdateSuccess, tutorielIndexUpdateError]);

    useEffect(() => {
        if (questionDeleteSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: deleteQuestionSuccessMessage });
            dispatch(questionListAction());
        } else if (questionDeleteError) {
            setModaleState({ isOpen: true, type: 'add-error', message: deleteQuestionErrorMessage });
        }
        dispatch(questionDeleteResetAction());
    }, [questionDeleteSuccess, questionDeleteError]);

    useEffect(() => {
        if (contactUpdateSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: 'Le contact a été mis à jour avec succès!' });
            dispatch(contactListAction());
        } else if (contactUpdateError) {
            setModaleState({ isOpen: true, type: 'add-error', message: 'Une erreur s\'est produite lors de la mise à jour du contact. Veuillez réessayer.' });
        }
        dispatch(contactUpdateResetAction());
    }, [contactUpdateSuccess, contactUpdateError]);

    useEffect(() => {
        if (contactDeleteSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: 'Le contact a été supprimé avec succès!' });
            dispatch(contactListAction());
        } else if (contactDeleteError) {
            setModaleState({ isOpen: true, type: 'add-error', message: 'Une erreur s\'est produite lors de la suppression du contact. Veuillez réessayer.' });
        }
        dispatch(contactDeleteResetAction());
    }, [contactDeleteSuccess, contactDeleteError]);

    function resetPubForm() {
        setPubEdit(false);
        setPubIdEdit(null);
        setPubTitre('');
        setPubDescription('');
        setPubUrl('');
        setPubImage(null);
    }

    useEffect(() => {
        setAffichageData(parametres.find(parametre => parametre.nom === 'affichage_data'));
        setAffichagePubs(parametres.find(parametre => parametre.nom === 'affichage_pubs'));
        setAffichageTutoriel(parametres.find(parametre => parametre.nom === 'affichage_tuto'));
        setAffichageFaq(parametres.find(parametre => parametre.nom === 'affichage_faq'));
    }, [parametres]);

    useEffect(() => {
        if (videos && videos.length > 0) {
            console.log('videos', videos)
            const foundVideo = videos.find(video => video.titre === "DomiSimpli");
            console.log('foundVideo', foundVideo);
            if (foundVideo) {
                setVideoId(foundVideo.id);
                if (foundVideo.video)
                    setVideoDomiSimpli(foundVideo.video.replace("media/", ""));
                setTitreDomiSimpli(foundVideo.titre);
                setDescriptionDomiSimpli(foundVideo.description);
                setAffichageVideo(foundVideo.display);
            }
        }
    }, [videos]);

    useEffect(() => {
        if (questionUpdateSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: updateSuccessMessage });
            dispatch(questionListAction());
        } else if (questionUpdateError) {
            setModaleState({ isOpen: true, type: 'add-error', message: updateErrorMessage });
            console.log(questionUpdateError);
        }
        dispatch(questionUpdateResetAction());
    }, [questionUpdateSuccess, questionUpdateError]);

    useEffect(() => {
        if (mailToSendAddSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: scheduleMailSuccessMessage });
            dispatch(mailToSendListAction());
        } else if (mailToSendAddError) {
            setModaleState({ isOpen: true, type: 'add-error', message: sendMailErrorMessage });
        }
        dispatch(mailToSendAddResetAction());
        setMailProgram(false);
    }, [mailToSendAddSuccess, mailToSendAddError]);

    useEffect(() => {
        if (sendMailSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: sendMailSuccessMessage });
        } else if (sendMailError) {
            setModaleState({ isOpen: true, type: 'add-error', message: sendMailErrorMessage });
        }
        dispatch(sendMailResetAction());
    }, [sendMailSuccess, sendMailError]);

    useEffect(() => {
        if (tutorielUpdateSuccess) {
            setModaleState({ isOpen: true, type: 'add-successs', message: tutorielIndexUpdateSuccessMessage });
            dispatch(tutorielListAction());
        } else if (tutorielUpdateError) {
            setModaleState({ isOpen: true, type: 'add-error', message: tutorielIndexUpdateErrorMessage });
        }
        dispatch(tutorielUpdateResetAction());
    }, [tutorielUpdateSuccess, tutorielUpdateError]);

    const resetDisplayForm = () => {
        setEdit(false);
        setIdEdit(null);
        setInformationImage('');
        setAffichageForm(false);
        setAffichageInfo(false);
        setTitre('');
        setTexte('');
        setImage(null);
    }

    const renderAccueil = () => {
        const handleImageChange = (e) => {
            if (e.target.files[0]) {
                setImage(e.target.files[0]);
            }
        };

        const handleSubmit = (e) => {
            e.preventDefault();

            const informationFormData = new FormData();
            informationFormData.append('titre', titre);
            informationFormData.append('description', texte);
            informationFormData.append('display', affichageInfo);


            if (image) {
                informationFormData.append('image', image)
            } else if (edit)
                informationFormData.append('image', informationImage);

            if (edit) {
                // Supposons que vous avez une logique pour gérer l'édition
                dispatch(informationUpdateAction(idEdit, informationFormData));
            } else {
                dispatch(informationAddAction(informationFormData));
            }
        };

        const handleEdit = (id) => {
            console.log("Édition de l'information avec l'id :", id);
            // Ici, ouvrez un formulaire d'édition ou dispatchez une action Redux
            let information = informations.find(info => info.id === id);
            setEdit(true);
            setIdEdit(id);
            setInformationImage(information.image.replace("media/", ""));
            setAffichageForm(true);
            setAffichageInfo(information.display);
            setTitre(information.titre);
            setTexte(information.description);
        };

        const handleDelete = (id) => {
            const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette information ?");

            // Si l'utilisateur confirme, procède à la suppression
            if (isConfirmed) {
                setEdit(false);
                // Ici, confirmez la suppression puis dispatchez une action Redux ou faites une requête API pour supprimer
                dispatch(informationDeleteAction(id))
            }
        };

        const handleForm = () => {
            setEdit(!edit);
            setAffichageForm(!affichageForm);
        }

        const handleAffichageDataChange = (e) => {
            const newActif = e.target.checked;
            // Mettez à jour l'état local en conservant l'objet complet et en ajustant seulement l'état `actif`
            setAffichageData(prevState => ({
                ...prevState,
                actif: newActif
            }));

            // Préparez l'objet à envoyer pour la mise à jour
            const parametreToUpdate = {
                ...affichageData,
                actif: newActif
            };

            // Dispatchez l'action de mise à jour
            if (affichageData && affichageData.id) {
                dispatch(parametreUpdateAction(affichageData.id, parametreToUpdate));
            } else {
                console.error("ID du paramètre est undefined");
            }
        };

        const handleEditorChange = (value) => {
            setTexte(value); // Traitez ou enregistrez la valeur ici
        }

        return (
            <>
                <div className="affichage-data">
                    <label htmlFor="affichageData">Affichage des données :</label>
                    <input
                        id="affichageData"
                        type="checkbox"
                        checked={affichageData && affichageData != undefined ? affichageData.actif : false}
                        onChange={(e) => handleAffichageDataChange(e)}
                    />
                </div>
                <div className="liste-info">
                    <h2>Liste des Informations</h2>
                    {informations.map((info) => (
                        <div key={info.id} className="info-item">
                            <p>{info.titre}</p>
                            <div key={info.id} className="info-item-button">
                                <button className="edit-button" onClick={() => handleEdit(info.id)}>Éditer</button>
                                <button className="delete-button" onClick={() => handleDelete(info.id)}>Supprimer
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="info-add-button">
                    <button className={!affichageForm ? "add-button" : "cancel-button"}
                        onClick={() => handleForm()}>
                        {!affichageForm ? "Nouveau" : "Annuler"}</button>
                </div>
                {
                    affichageForm && (
                        <div>
                            <h1>Administration - Accueil</h1>
                            <div className="affichage-data-input">
                                <label htmlFor="affichageInfo">Aficher l'information :</label>
                                <input
                                    id="affichageInfo"
                                    type="checkbox"
                                    checked={affichageInfo}
                                    onChange={(e) => setAffichageInfo(e.target.checked)}
                                />
                            </div>
                            <form onSubmit={handleSubmit} className="admin-form">
                                <input type="text" placeholder="Titre" value={titre}
                                    onChange={(e) => setTitre(e.target.value)} />
                                <div className='text-editor'>
                                    <RichTextEditor value={texte != undefined ? texte : ''} onChange={handleEditorChange} />
                                </div>
                                <input type="file" onChange={handleImageChange} />
                                <button type="submit">Enregistrer</button>
                            </form>
                        </div>
                    )
                }

            </>
        );
    };

    const renderDomisimpli = () => {

        const handleVideoChange = (e) => {
            if (e.target.files[0]) {
                setVideoDomiSimpli(e.target.files[0]);
            }
        };

        const handleSubmitDomiSimpli = (e) => {
            e.preventDefault();

            const formData = new FormData();
            formData.append('titre', titreDomiSimpli);
            formData.append('description', descriptionDomiSimpli);
            formData.append('display', affichageVideo);
            if (videoDomiSimpli) formData.append('video', videoDomiSimpli);
            else if (edit) formData.append('video', videoDomiSimpli);

            // Dispatchez l'action pour mettre à jour le texte et la vidéo
            dispatch(videoUpdateAction(videoId, formData));
        };

        const handleEditorChange = (value) => {
            setDescriptionDomiSimpli(value); // Traitez ou enregistrez la valeur ici
        };

        const handlePubEditorChange = (value) => {
            setPubDescription(value)
        }

        const handlePubImageChange = (e) => {
            if (e.target.files[0]) {
                setPubImage(e.target.files[0]);
            }
        };

        const handlePubSubmit = (e) => {
            e.preventDefault();

            const formData = new FormData();
            formData.append('titre', pubTitre);
            formData.append('description', pubDescription);
            formData.append('display', true);
            formData.append('url', pubUrl);
            if (pubImage) formData.append('image', pubImage);

            if (pubEdit && pubIdEdit) {
                console.log('pubIdEdit', pubIdEdit, 'formData', formData);
                dispatch(pubUpdateAction(pubIdEdit, formData));
            } else {
                console.log('add pub formData', formData);
                dispatch(pubAddAction(formData));
            }

            // Réinitialiser le formulaire
            setPubEdit(false);
            setPubIdEdit(null);
            setPubTitre('');
            setPubDescription('');
            setPubUrl('');
            setPubImage(null);
        };

        const handlePubEdit = (id) => {
            const pub = pubs.find(p => p.id === id);
            setPubEdit(true);
            setPubIdEdit(id);
            setPubTitre(pub.titre);
            setPubDescription(pub.description);
            setPubUrl(pub.url);
            // Note: Gérer l'image existante si nécessaire
        };

        const handlePubDelete = (id) => {
            dispatch(pubDeleteAction(id));
        };

        const handleAffichagePubChange = (e) => {
            const newActif = e.target.checked;
            // Mettez à jour l'état local en conservant l'objet complet et en ajustant seulement l'état `actif`
            setAffichagePubs(prevState => ({
                ...prevState,
                actif: newActif
            }));

            // Préparez l'objet à envoyer pour la mise à jour
            const parametreToUpdate = {
                ...affichagePubs,
                actif: newActif
            };

            // Dispatchez l'action de mise à jour
            if (affichagePubs && affichagePubs.id) {
                dispatch(parametreUpdateAction(affichagePubs.id, parametreToUpdate));
            } else {
                console.error("ID du paramètre est undefined");
            }
        }

        const handleAffichageVideoChange = (e) => {
            setAffichageVideo(e.target.checked)
        }



        return (
            <div className="liste-info">
                <h2>Édition DomiSimpli</h2>
                <form onSubmit={handleSubmitDomiSimpli} className="admin-form">
                    <div className="affichage-video">
                        <label htmlFor="affichageVideo">Affichage de la video :</label>
                        <input
                            id="affichageVideo"
                            type="checkbox"
                            checked={affichageVideo ? affichageVideo : false}
                            onChange={(e) => handleAffichageVideoChange(e)}
                        />
                    </div>
                    <input type="text" placeholder="Titre" value={titreDomiSimpli}
                        onChange={(e) => setTitreDomiSimpli(e.target.value)} />
                    <div className='text-editor'>
                        <RichTextEditor value={descriptionDomiSimpli != undefined ? descriptionDomiSimpli : ''} onChange={handleEditorChange} />
                    </div>
                    <input type="file" onChange={handleVideoChange} />
                    <button type="submit">Enregistrer</button>
                </form>
                <div className="publicites-list">
                    <h2>Gestion des Publicités</h2>
                    <div className="affichage-pub">
                        <label htmlFor="affichagePubs">Affichage des publicités :</label>
                        <input
                            id="affichagePubs"
                            type="checkbox"
                            checked={affichagePubs && affichagePubs != undefined ? affichagePubs.actif : false}
                            onChange={(e) => handleAffichagePubChange(e)}
                        />
                    </div>
                    {pubs.map((pub) => (
                        <div key={pub.id} className="publicite-item">
                            <p>{pub.titre}</p>
                            <div>
                                <button onClick={() => handlePubEdit(pub.id)} className="edit-button">Éditer</button>
                                <button onClick={() => handlePubDelete(pub.id)} className="delete-button">Supprimer
                                </button>
                            </div>
                        </div>
                    ))}
                    <div className="publicites-actions">
                        <button onClick={() => {
                            setPubEdit(!pubEdit)
                            setPubIdEdit(null)
                        }}
                            className={!pubEdit ? "add-publicite-button" : "cancel-publicite-button"}>
                            {!pubEdit ? 'Ajouter une nouvelle publicité' : 'Annuler'}</button>
                    </div>
                </div>
                {pubEdit && (
                    <form onSubmit={handlePubSubmit} className="admin-form">
                        <input type="text" placeholder="Titre" value={pubTitre}
                            onChange={(e) => setPubTitre(e.target.value)} />
                        <div className='text-editor'>
                            <RichTextEditor value={pubDescription != undefined ? pubDescription : ''} onChange={handlePubEditorChange} />
                        </div>
                        <input type="text" placeholder="URL" value={pubUrl}
                            onChange={(e) => setPubUrl(e.target.value)} />
                        <input type="file" onChange={handlePubImageChange} />
                        <button type="submit" className="add-button">Enregistrer</button>
                    </form>
                )}
            </div>
        );

    };

    const renderTutoriels = () => {
        const handleAffichageTutoChange = (e) => {
            const newActif = e.target.checked;
            // Mettez à jour l'état local en conservant l'objet complet et en ajustant seulement l'état `actif`
            setAffichageTutoriel(prevState => ({
                ...prevState,
                actif: newActif
            }));

            // Préparez l'objet à envoyer pour la mise à jour
            const parametreToUpdate = {
                ...affichageTutoriel,
                actif: newActif
            };

            // Dispatchez l'action de mise à jour
            if (affichageTutoriel && affichageTutoriel.id) {
                dispatch(parametreUpdateAction(affichageTutoriel.id, parametreToUpdate));
            } else {
                console.error("ID du paramètre est undefined");
            }
        };

        return (
            <>
                <div className="affichage-data">
                    <label htmlFor="affichageTutoriel">Affichage des tutos :</label>
                    <input
                        id="affichageTutoriel"
                        type="checkbox"
                        checked={affichageTutoriel && affichageTutoriel != undefined ? affichageTutoriel.actif : false}
                        onChange={(e) => handleAffichageTutoChange(e)}
                    />
                </div>
                <IndexEditor indexs={tutorielIndexs} tutoriels={tutoriels} />
            </>
        )
    }

    const renderContact = () => {
        const handleResponse = (contactId) => {
            const isConfirmed = window.confirm("Êtes-vous sûr de vouloir marquer cette demande comme répondue ?");

            // Si l'utilisateur confirme, procède à la suppression
            if (isConfirmed) {
                let contact = {
                    ...contacts.find(c => c.id === contactId),
                    repondu: true
                }
                dispatch(contactUpdateAction(contactId, contact));
            }
        }

        const handleDeleteContact = (contactId) => {
            const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette demande ?");

            // Si l'utilisateur confirme, procède à la suppression
            if (isConfirmed) {
                dispatch(contactDeleteAction(contactId));
            }
        }

        return (
            <div className="liste-info">
                <Helmet>
                    <title>Administration - Contact</title>
                </Helmet>
                <div className="contact-list-container">
                    <h2 className="contact-list-title">Liste des contacts</h2>
                    <div className="contact-list-toggle">
                        <span style={{ marginRight: '10px' }}>{showResponded ? "Afficher non répondues" : "Afficher répondues"}</span>
                        <Toggle
                            defaultChecked={showResponded}
                            icons={false}
                            onChange={() => setShowResponded(!showResponded)} />
                    </div>
                    {
                        contacts && contacts.length > 0 ? (
                            <div className="contact-list">
                                {contacts.filter(contact => contact.repondu === showResponded).map(contact => (
                                    <div key={contact.id} className="contact-item">
                                        <div className="contact-info">
                                            <p className="contact-name">{contact.civilite} {contact.prenom} {contact.nom}</p>
                                            <p className="contact-email">{contact.email}</p>
                                            <p className="contact-phone">{contact.telephone}</p>
                                            <p className="contact-subject">{contact.sujet}</p>
                                            <p className="contact-message">{contact.message}</p>
                                            <p className="contact-document">{contact.document}</p>
                                        </div>
                                        <div className="contact-actions">
                                            <a href={`mailto:${contact.email}?subject=Réponse à votre demande&body=Bonjour ${contact.prenom},%0D%0A%0D%0AVotre demande a été reçue.%0D%0A%0D%0A`} className="admin-reply-button">Répondre</a>
                                            <button className="admin-mark-as-responded-button" onClick={() => handleResponse(contact.id)}>Marquer comme répondu</button>
                                            <button className="admin-delete-button" onClick={() => handleDeleteContact(contact.id)}>Supprimer</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="no-contacts">
                                <h2>Pas de contacts</h2>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }

    const renderDivers = () => {
        const handleAffichageFaqChange = (e) => {
            const newActif = e.target.checked;
            // Mettez à jour l'état local en conservant l'objet complet et en ajustant seulement l'état `actif`
            setAffichageFaq(prevState => ({
                ...prevState,
                actif: newActif
            }));

            // Préparez l'objet à envoyer pour la mise à jour
            const parametreToUpdate = {
                ...affichageFaq,
                actif: newActif
            };

            // Dispatchez l'action de mise à jour
            if (affichageFaq && affichageFaq.id) {
                dispatch(parametreUpdateAction(affichageFaq.id, parametreToUpdate));
            } else {
                console.error("ID du paramètre est undefined");
            }
            console.log('handleAffichageFaqChange');
        };

        const handleDisplayChange = (questionId, isChecked) => {
            // Dispatchez une action pour mettre à jour le paramètre 'display' de la question
            let q = questions.find(q => q.id === questionId);
            let question = {
                ...q,
                display: isChecked
            };

            dispatch(questionUpdateAction(questionId, question));
        };

        const handleAnswer = (questionId) => {
            setSelectedQuestionId(questionId);
            setShowAnswerForm(true);
        };

        const handleSubmitAnswer = (e) => {
            e.preventDefault();
            // Récupérez les données du formulaire
            let reponse = {
                texte: reponseTexte,
                question: selectedQuestionId
            };

            dispatch(reponseAddAction(reponse));
            setShowAnswerForm(false); // Fermez le formulaire après la soumission
        };

        const handleEditorChange = (value) => {
            setReponseTexte(value); // Traitez ou enregistrez la valeur ici
        }

        const handleDelete = (questionId) => {
            const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette question ?");

            // Si l'utilisateur confirme, procède à la suppression
            if (isConfirmed) {
                dispatch(questionDeleteAction(questionId));
            }
        }

        return (
            <>
                <div className="affichage-data">
                    <label htmlFor="affichageFaq">Affichage de la FAQ :</label>
                    <input
                        id="affichageFaq"
                        type="checkbox"
                        checked={affichageFaq && affichageFaq != undefined ? affichageFaq.actif : false}
                        onChange={(e) => handleAffichageFaqChange(e)}
                    />
                </div>
                {questions.map(question => (
                    <div key={question.id}
                        className={`faq-admin-item ${question.reponses && question.reponses.length === 0 ? 'no-answer' : ''} ${selectedQuestionId === question.id ? 'question-highlighted' : ''}`}>
                        <div className="faq-admin-item-content">
                            <p>{question.titre}</p>
                            <p>{question.texte}</p>
                        </div>
                        <div className="display-checkbox">
                            <label htmlFor="display">Afficher</label>

                            <input
                                type="checkbox"
                                checked={question.display}
                                onChange={(e) => handleDisplayChange(question.id, e.target.checked)}
                            />
                        </div>
                        <div className="faq-admin-item-buttons">
                            <button className="respond-button" onClick={() => handleAnswer(question.id)}>Répondre</button>
                            <button className="admin-delete-button" onClick={() => handleDelete(question.id)}>Supprimer</button>
                        </div>
                    </div>
                ))}
                {showAnswerForm && (
                    <div className="form-reponse">
                        <button onClick={() => setShowAnswerForm(false)} className="close-form-btn">Fermer</button>

                        <form onSubmit={handleSubmitAnswer}>
                            <div className='text-editor'>
                                <RichTextEditor value={reponseTexte != undefined ? reponseTexte : ''} onChange={handleEditorChange} />
                            </div>
                            <button type="submit" className="add-button">Enregistrer</button>
                        </form>
                    </div>

                )}
            </>
        )
    }

    const renderNewsLetter = () => {
        const handleSendNow = () => {
            dispatch(sendMailAction(emailSubject, emailContent));
        };

        const handleScheduleSend = () => {
            const scheduledDateTime = `${sendDate}T${sendTime}`;
            dispatch(mailToSendAddAction(emailSubject, emailContent, scheduledDateTime));
            setMailProgram(true);
        };

        const handleEditorChange = (value) => {
            setEmailContent(value); // Traitez ou enregistrez la valeur ici
        }

        return (
            <div className="newsletter-container">
                <h2>News Letter</h2>
                {
                    mailCountData && mailCountData > 0 ? (
                        <div className='compteur-mails'>
                            <p>Vous avez {mailCountData} emails dans la liste d'envoi</p>
                        </div>
                    ) : null
                }
                <input
                    className="email-subject"
                    placeholder="Sujet de l'email"
                    value={emailSubject}
                    onChange={(e) => setEmailSubject(e.target.value)}
                />
                <div className='text-editor'>
                    <RichTextEditor value={texte != undefined ? texte : ''} onChange={handleEditorChange} />
                </div>
                <div className="send-options">
                    <input
                        type="date"
                        className="send-date"
                        value={sendDate}
                        onChange={(e) => setSendDate(e.target.value)}
                    />
                    <input
                        type="time"
                        className="send-time"
                        value={sendTime}
                        onChange={(e) => setSendTime(e.target.value)}
                    />
                    <button className="send-now" onClick={handleSendNow}>Envoyer Maintenant</button>
                    <button className="schedule-send" onClick={handleScheduleSend}>Programmer l'envoi</button>
                </div>
            </div>
        );
    };

    const handleCloseModale = () => {
        setModaleState({ ...modaleState, isOpen: false });
    };

    return (
        <div className="administration">
            <Helmet>
                <title>Administration</title>
            </Helmet>
            {
                loading || videoLoading || pubLoading || loadingTutoriel || tutorielIndexLoading ? (
                    <div>Chargement...</div>
                ) : error || videoError || pubError || errorTutoriel || tutorielIndexError ? (
                    <div>{error}</div>
                ) : (
                    <div className="administration">
                        <div className="tabs">
                            <button className={activeTab === 'accueil' ? 'bar-active' : 'bar-normal'}
                                onClick={() => setActiveTab('accueil')}>Accueil
                            </button>
                            <button className={activeTab === 'domisimpli' ? 'bar-active' : 'bar-normal'}
                                onClick={() => setActiveTab('domisimpli')}>Découvrez DomiSimpli
                            </button>
                            <button className={activeTab === 'tutoriels' ? 'bar-active' : 'bar-normal'}
                                onClick={() => setActiveTab('tutoriels')}>Tutoriels
                            </button>
                            {/* <button className={activeTab === 'contact' ? 'active' : ''} onClick={() => setActiveTab('contact')}>Contact</button> */}
                            <button className={activeTab === 'divers' ? 'bar-active' : 'bar-normal'}
                                onClick={() => setActiveTab('divers')}>Divers
                            </button>
                            <button className={activeTab === 'contact' ? 'bar-active' : 'bar-normal'}
                                onClick={() => setActiveTab('contact')}>Contact
                                {
                                    contacts && contacts.length > 0 ? (
                                        <span className="contact-badge">{contacts.filter(c => c.repondu === false).length}</span>
                                    ) : null
                                }
                            </button>
                            <button className={activeTab === 'news_letter' ? 'bar-active' : 'bar-normal'}
                                onClick={() => setActiveTab('news_letter')}>News letter
                            </button>
                        </div>
                        <div className="tab-content">
                            {activeTab === 'accueil' && renderAccueil()}
                            {activeTab === 'domisimpli' && renderDomisimpli()}
                            {activeTab === 'tutoriels' && renderTutoriels()}
                            {activeTab === 'contact' && renderContact()}
                            {activeTab === 'divers' && renderDivers()}
                            {activeTab === 'news_letter' && renderNewsLetter()}
                        </div>
                    </div>
                )
            }
            <Modale
                isOpen={modaleState.isOpen}
                onClose={handleCloseModale}
                title={modaleState.type === 'add-successs' ? 'Succès' : 'Erreur'}
                type={modaleState.type} // Passez le type ici
            >
                <p>{modaleState.message}</p>
            </Modale>
        </div>
    );
}

export default Administration