import {
    CGV_LIST_REQUEST,
    CGV_LIST_SUCCESS,
    CGV_LIST_FAIL,

    CGV_ADD_REQUEST,
    CGV_ADD_SUCCESS,
    CGV_ADD_FAIL,
    CGV_ADD_RESET,

    CGV_DELETE_REQUEST,
    CGV_DELETE_SUCCESS,
    CGV_DELETE_FAIL,
    CGV_DELETE_RESET,

    CGV_UPDATE_REQUEST,
    CGV_UPDATE_SUCCESS,
    CGV_UPDATE_FAIL,
    CGV_UPDATE_RESET
} from '../constants/cgvConstants';

import axios from 'axios';

export const cgvListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CGV_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/cgv/get_cgvs/`,
            config
        )

        dispatch({
            type: CGV_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CGV_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const cgvAddAction = (cgv) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CGV_ADD_REQUEST,
        })

        let formData = new FormData();
        // Ajoutez chaque champ de l'objet candidature au formData
        for (const key in cgv) {
            if (cgv.hasOwnProperty(key)) {
                formData.append(key, cgv[key]);
            }
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }

        const { data } = await axios.post(
            `/api/cgv/add_cgv/`,
            formData,
            config
        )

        dispatch({
            type: CGV_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CGV_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const cgvDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CGV_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(
            `/api/cgv/delete_cgv/${id}/`,
            config
        )

        dispatch({
            type: CGV_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CGV_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const cgvUpdateAction = (cgv_id, cgv) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CGV_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        
        const { data } = await axios.put(
            `/api/cgv/update_cgv/${cgv_id}/`,
            cgv,
            config
        )

        dispatch({
            type: CGV_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CGV_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const cgvAddResetAction = () => (dispatch) => {
    dispatch({ type: CGV_ADD_RESET });
}

export const cgvDeleteResetAction = () => (dispatch) => {
    dispatch({ type: CGV_DELETE_RESET });
}

export const cgvUpdateResetAction = () => (dispatch) => {
    dispatch({ type: CGV_UPDATE_RESET });
}