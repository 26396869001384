import {
    PRIVACY_POLICYS_LIST_REQUEST,
    PRIVACY_POLICYS_LIST_SUCCESS,
    PRIVACY_POLICYS_LIST_FAIL,

    PRIVACY_POLICYS_ADD_REQUEST,
    PRIVACY_POLICYS_ADD_SUCCESS,
    PRIVACY_POLICYS_ADD_FAIL,
    PRIVACY_POLICYS_ADD_RESET,

    PRIVACY_POLICYS_DELETE_REQUEST,
    PRIVACY_POLICYS_DELETE_SUCCESS,
    PRIVACY_POLICYS_DELETE_FAIL,
    PRIVACY_POLICYS_DELETE_RESET,

    PRIVACY_POLICYS_UPDATE_REQUEST,
    PRIVACY_POLICYS_UPDATE_SUCCESS,
    PRIVACY_POLICYS_UPDATE_FAIL,
    PRIVACY_POLICYS_UPDATE_RESET
} from '../constants/privacyPolicysConstants';

import axios from 'axios';

export const privacyPolicyListAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRIVACY_POLICYS_LIST_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await axios.get(
            `/api/privacy-policy/get_privacy_policys/`,
            config
        )

        dispatch({
            type: PRIVACY_POLICYS_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PRIVACY_POLICYS_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const privacyPolicyAddAction = (privacyPolicy) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRIVACY_POLICYS_ADD_REQUEST,
        })

        let formData = new FormData();
        // Ajoutez chaque champ de l'objet candidature au formData
        for (const key in privacyPolicy) {
            if (privacyPolicy.hasOwnProperty(key)) {
                formData.append(key, privacyPolicy[key]);
            }
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }

        const { data } = await axios.post(
            `/api/privacy-policy/add_privacy-policy/`,
            formData,
            config
        )

        dispatch({
            type: PRIVACY_POLICYS_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PRIVACY_POLICYS_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const privacyPolicyDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRIVACY_POLICYS_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(
            `/api/privacy-policy/delete_privacy-policy/${id}/`,
            config
        )

        dispatch({
            type: PRIVACY_POLICYS_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PRIVACY_POLICYS_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const privacyPolicyUpdateAction = (privacyPolicy_id, privacyPolicy) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRIVACY_POLICYS_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        
        const { data } = await axios.put(
            `/api/privacy-policy/update_privacy-policy/${privacyPolicy_id}/`,
            privacyPolicy,
            config
        )

        dispatch({
            type: PRIVACY_POLICYS_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PRIVACY_POLICYS_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const privacyPolicyAddResetAction = () => (dispatch) => {
    dispatch({ type: PRIVACY_POLICYS_ADD_RESET });
}

export const privacyPolicyDeleteResetAction = () => (dispatch) => {
    dispatch({ type: PRIVACY_POLICYS_DELETE_RESET });
}

export const privacyPolicyUpdateResetAction = () => (dispatch) => {
    dispatch({ type: PRIVACY_POLICYS_UPDATE_RESET });
}