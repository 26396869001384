import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import './Index.css';

function SubIndex({ indexs, parentId, level, onSelect, deployedIndexIds, toggleDeploy }) {
    const hasChildren = (id) => indexs.some(index => index.parent === id);

    const isVisible = deployedIndexIds.includes(parentId);
    // Estimez une hauteur maximale suffisante pour votre contenu. Vous devrez peut-être ajuster cette valeur.
    const estimatedMaxHeight = 1000; // Exemple : 1000px
    const animationProps = useSpring({
        to: { maxHeight: isVisible ? estimatedMaxHeight + 'px' : '0px', opacity: isVisible ? 1 : 0 },
        from: { maxHeight: '0px', opacity: 0 },
        config: { mass: 1, tension: 1500, friction: 100 },
    });

    const renderIndicator = (id) => (
        <span className={`indicator ${deployedIndexIds.includes(id) ? 'open' : ''}`}>
            {hasChildren(id) ? deployedIndexIds.includes(id) ? '▼' : '▶' : ''}
        </span>
    );

    return (
        <animated.div style={animationProps} className={`subindex-container ${isVisible ? 'deployed' : ''}`}>
            {indexs
                .filter(index => index.parent === parentId)
                .sort((a, b) => a.ordre - b.ordre)
                .map(index => (
                    <div key={index.id} className={`subindex-item level-${level}`}>
                        <div className="subindex-name" onClick={() => {
                            if (indexs.some(childIndex => childIndex.parent === index.id)) {
                                toggleDeploy(index.id);
                            } else {
                                onSelect(index.tutoriel);
                            }
                        }}
                        >
                            {renderIndicator(index.id)} {index.nom}
                        </div>
                        <SubIndex indexs={indexs} parentId={index.id} level={level + 1} onSelect={onSelect} deployedIndexIds={deployedIndexIds} toggleDeploy={toggleDeploy} />
                    </div>
                ))}
        </animated.div>
    );
}


function Index({ indexs, onSelect }) {
    const [deployedIndexIds, setDeployedIndexIds] = useState([]);

    const hasChildren = (id) => indexs.some(index => index.parent === id);

    const renderIndicator = (id) => (
        <span className={`indicator ${deployedIndexIds.includes(id) ? 'open' : ''}`}>
            {hasChildren(id) ? deployedIndexIds.includes(id) ? '▼' : '▶' : ''}
        </span>
    );

    const toggleDeploy = (id) => {
        setDeployedIndexIds(prevIds =>
            prevIds.includes(id) ? prevIds.filter(prevId => prevId !== id) : [...prevIds, id]
        );
    };

    return (
        <div className="tutoriel-index">
            {indexs
                .filter(index => index.parent === null || index.parent === undefined)
                .sort((a, b) => a.ordre - b.ordre)
                .map(index => (
                    <div key={index.id} className="index-item level-0">
                        <div className="index-name" onClick={() => {
                            if (indexs.some(childIndex => childIndex.parent === index.id)) {
                                toggleDeploy(index.id);
                            } else {
                                onSelect(index.tutoriel);
                            }
                        }}
                        >
                            {renderIndicator(index.id)} {index.nom}
                        </div>
                        <SubIndex indexs={indexs} parentId={index.id} level={1} onSelect={onSelect} deployedIndexIds={deployedIndexIds} toggleDeploy={toggleDeploy} />
                    </div>
                ))}
        </div>
    );
}

export default Index;
