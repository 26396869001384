import {
    TUTORIEL_LIST_REQUEST,
    TUTORIEL_LIST_SUCCESS,
    TUTORIEL_LIST_FAIL,

    TUTORIEL_ADD_REQUEST,
    TUTORIEL_ADD_SUCCESS,
    TUTORIEL_ADD_FAIL,
    TUTORIEL_ADD_RESET,

    TUTORIEL_DELETE_REQUEST,
    TUTORIEL_DELETE_SUCCESS,
    TUTORIEL_DELETE_FAIL,
    TUTORIEL_DELETE_RESET,

    TUTORIEL_UPDATE_REQUEST,
    TUTORIEL_UPDATE_SUCCESS,
    TUTORIEL_UPDATE_FAIL,
    TUTORIEL_UPDATE_RESET
} from '../constants/tutorielConstants';

// Compare this snippet from user-interface/src/redux/reducers/tutorielReducers.js:
export const tutorielListReducer = (state = { tutoriels: [] }, action) => {
    switch (action.type) {
        case TUTORIEL_LIST_REQUEST:
            return { loading: true, tutoriels: [] };
        case TUTORIEL_LIST_SUCCESS:
            return { loading: false, tutoriels: action.payload };
        case TUTORIEL_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/tutorielReducers.js:
export const tutorielAddReducer = (state = {}, action) => {
    switch (action.type) {
        case TUTORIEL_ADD_REQUEST:
            return { loading: true };
        case TUTORIEL_ADD_SUCCESS:
            return { loading: false, success: true, tutoriel: action.payload };
        case TUTORIEL_ADD_FAIL:
            return { loading: false, error: action.payload };
        case TUTORIEL_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/tutorielReducers.js:
export const tutorielDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case TUTORIEL_DELETE_REQUEST:
            return { loading: true };
        case TUTORIEL_DELETE_SUCCESS:
            return { loading: false, success: true };
        case TUTORIEL_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case TUTORIEL_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/tutorielReducers.js:
export const tutorielUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case TUTORIEL_UPDATE_REQUEST:
            return { loading: true };
        case TUTORIEL_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case TUTORIEL_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case TUTORIEL_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}
