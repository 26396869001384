import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import Modale from '../../components/Modale/Modale';

import { contactAddAction, contactAddResetAction } from '../../redux/actions/contactActions';

import './Contact.css'; // Assurez-vous d'avoir le bon chemin pour votre fichier CSS

function Contact() {
    const dispatch = useDispatch();
    const [modaleState, setModaleState] = useState({ isOpen: false, type: '', message: '' });

    let successMessage = 'Votre demande a été soumise avec succès! Nous vous contacterons sous peu.';
    let errorMessage = 'Une erreur s\'est produite lors de la soumission de votre demande. Veuillez réessayer.';

    const contactAdd = useSelector((state) => state.contactAdd);
    const { loading, error, success, contact } = contactAdd;

    const [formData, setFormData] = useState({
        civilite: '',
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        message: '',
        document: null,
    });

    useEffect(() => {
        if (success) {
            // Afficher une modale de succès
            setModaleState({ isOpen: true, type: 'add-success', message: successMessage });
        }
        else if (error) {
            // Afficher une modale d'erreur
            setModaleState({ isOpen: true, type: 'add-error', message: errorMessage });
        }
        dispatch(contactAddResetAction());
    }, [success, error]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            document: file,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Vous pouvez envoyer les données du formulaire vers votre API ou effectuer une action nécessaire ici
        console.log(formData);

        // Réinitialiser le formulaire après la soumission
        setFormData({
            civilite: '',
            nom: '',
            prenom: '',
            email: '',
            telephone: '',
            message: '',
            document: null,
        });

        dispatch(contactAddAction(formData));
    };

    const handleCloseModale = () => {
        setModaleState({ ...modaleState, isOpen: false });
    };

    return (
        <div className="contact-container">
            <Helmet>
                <title>Contact</title>
                <meta name="description" content="Contact" />
                <meta name="keywords" content="services, coode-nt, domisimpli, googe play store, app store" />
            </Helmet>
            <h2>Formulaire de Contact</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Civilité</label>
                    <select name="civilite" value={formData.civilite} onChange={handleChange} required>
                        <option value="">-- Sélectionnez --</option>
                        <option value="Monsieur">Monsieur</option>
                        <option value="Madame">Madame</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Nom</label>
                    <input type="text" name="nom" value={formData.nom} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Prénom</label>
                    <input type="text" name="prenom" value={formData.prenom} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Numéro de Téléphone</label>
                    <input type="tel" name="telephone" value={formData.telephone} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Message</label>
                    <textarea name="message" value={formData.message} onChange={handleChange} rows="4" required></textarea>
                </div>
                <div className="form-group">
                    <label>Document (Fichier)</label>
                    <input type="file" accept=".pdf,.doc,.docx" name="document" onChange={handleFileChange} />
                </div>
                <button type="submit">Envoyer</button>
            </form>
            <Modale
                isOpen={modaleState.isOpen}
                onClose={handleCloseModale}
                title={modaleState.type === 'add-success' ? 'Succès' : 'Erreur'}
                type={modaleState.type} // Passez le type ici
            >
                <p>{modaleState.message}</p>
            </Modale>
        </div>
    );
}

export default Contact;
