import React, { useState } from 'react';
import HtmlContent from '../../components/HtmlContent/HtmlContent';
import './FAQItem.css';

function FAQItem({ question, reponses }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="faq-item">
            {/* Titre de la question */}
            <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
                <div className="faq-question-detail">
                    {question.titre}
                    <p className="faq-question-texte">{question.texte}</p>
                </div>
            </div>
            {isOpen && (
                <div className="faq-reponses">
                    {/* Texte détaillé de la question */}

                    {reponses.length > 0 ?
                        reponses.map((reponse, index) => (
                            <div key={index} className="faq-reponse">
                                {/* Texte de la réponse */}
                                <HtmlContent html={reponse.texte} />
                            </div>
                        )) : (
                            <p className="faq-reponse">Aucune réponse pour cette question</p>
                        )}
                </div>
            )}
        </div>
    );
}

export default FAQItem;
