export const REPONSE_LIST_REQUEST = 'REPONSE_LIST_REQUEST';
export const REPONSE_LIST_SUCCESS = 'REPONSE_LIST_SUCCESS';
export const REPONSE_LIST_FAIL = 'REPONSE_LIST_FAIL';

export const REPONSE_ADD_REQUEST = 'REPONSE_ADD_REQUEST';
export const REPONSE_ADD_SUCCESS = 'REPONSE_ADD_SUCCESS';
export const REPONSE_ADD_FAIL = 'REPONSE_ADD_FAIL';
export const REPONSE_ADD_RESET = 'REPONSE_ADD_RESET';

export const REPONSE_DELETE_REQUEST = 'REPONSE_DELETE_REQUEST';
export const REPONSE_DELETE_SUCCESS = 'REPONSE_DELETE_SUCCESS';
export const REPONSE_DELETE_FAIL = 'REPONSE_DELETE_FAIL';
export const REPONSE_DELETE_RESET = 'REPONSE_DELETE_RESET';

export const REPONSE_UPDATE_REQUEST = 'REPONSE_UPDATE_REQUEST';
export const REPONSE_UPDATE_SUCCESS = 'REPONSE_UPDATE_SUCCESS';
export const REPONSE_UPDATE_FAIL = 'REPONSE_UPDATE_FAIL';
export const REPONSE_UPDATE_RESET = 'REPONSE_UPDATE_RESET';
