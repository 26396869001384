export const VIDEO_LIST_REQUEST = 'VIDEO_LIST_REQUEST';
export const VIDEO_LIST_SUCCESS = 'VIDEO_LIST_SUCCESS';
export const VIDEO_LIST_FAIL = 'VIDEO_LIST_FAIL';

export const VIDEO_ADD_REQUEST = 'VIDEO_ADD_REQUEST';
export const VIDEO_ADD_SUCCESS = 'VIDEO_ADD_SUCCESS';
export const VIDEO_ADD_FAIL = 'VIDEO_ADD_FAIL';
export const VIDEO_ADD_RESET = 'VIDEO_ADD_RESET';

export const VIDEO_DELETE_REQUEST = 'VIDEO_DELETE_REQUEST';
export const VIDEO_DELETE_SUCCESS = 'VIDEO_DELETE_SUCCESS';
export const VIDEO_DELETE_FAIL = 'VIDEO_DELETE_FAIL';
export const VIDEO_DELETE_RESET = 'VIDEO_DELETE_RESET';

export const VIDEO_UPDATE_REQUEST = 'VIDEO_UPDATE_REQUEST';
export const VIDEO_UPDATE_SUCCESS = 'VIDEO_UPDATE_SUCCESS';
export const VIDEO_UPDATE_FAIL = 'VIDEO_UPDATE_FAIL';
export const VIDEO_UPDATE_RESET = 'VIDEO_UPDATE_RESET';
