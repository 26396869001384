import {
    QUESTION_LIST_REQUEST,
    QUESTION_LIST_SUCCESS,
    QUESTION_LIST_FAIL,

    QUESTION_ADD_REQUEST,
    QUESTION_ADD_SUCCESS,
    QUESTION_ADD_FAIL,
    QUESTION_ADD_RESET,

    QUESTION_DELETE_REQUEST,
    QUESTION_DELETE_SUCCESS,
    QUESTION_DELETE_FAIL,
    QUESTION_DELETE_RESET,

    QUESTION_UPDATE_REQUEST,
    QUESTION_UPDATE_SUCCESS,
    QUESTION_UPDATE_FAIL,
    QUESTION_UPDATE_RESET
} from '../constants/questionConstants';

import axios from 'axios';

export const questionListAction = () => async (dispatch, getState) => {
    try {
        dispatch({ type: QUESTION_LIST_REQUEST });

        const { userLogin: { userInfo } } = getState();

        // Créer l'instance axios avec condition sur l'existence de userInfo
        const instance = axios.create({
            headers: {
                ...userInfo ? { 'Authorization': `Bearer ${userInfo.token}` } : {},
                'Content-Type': 'application/json',
            },
        });

        console.log('instance');

        const { data } = await instance.get(`/api/question/get_questions/`);

        dispatch({
            type: QUESTION_LIST_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: QUESTION_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const questionAddAction = (titre, texte) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QUESTION_ADD_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }

        const { data } = await axios.post(
            `/api/question/add_question/`,
            { titre, texte },
            config
        )

        dispatch({
            type: QUESTION_ADD_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: QUESTION_ADD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const questionDeleteAction = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QUESTION_DELETE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        const { data } = await axios.delete(
            `/api/question/delete_question/${id}/`,
            config
        )

        dispatch({
            type: QUESTION_DELETE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: QUESTION_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const questionUpdateAction = (question_id, question) => async (dispatch, getState) => {
    try {
        dispatch({
            type: QUESTION_UPDATE_REQUEST,
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }

        
        const { data } = await axios.put(
            `/api/question/update_question/${question_id}/`,
            question,
            config
        )

        dispatch({
            type: QUESTION_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: QUESTION_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
}

export const questionAddResetAction = () => (dispatch) => {
    dispatch({ type: QUESTION_ADD_RESET });
}

export const questionDeleteResetAction = () => (dispatch) => {
    dispatch({ type: QUESTION_DELETE_RESET });
}

export const questionUpdateResetAction = () => (dispatch) => {
    dispatch({ type: QUESTION_UPDATE_RESET });
}