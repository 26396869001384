export const CGU_LIST_REQUEST = 'CGU_LIST_REQUEST';
export const CGU_LIST_SUCCESS = 'CGU_LIST_SUCCESS';
export const CGU_LIST_FAIL = 'CGU_LIST_FAIL';

export const CGU_ADD_REQUEST = 'CGU_ADD_REQUEST';
export const CGU_ADD_SUCCESS = 'CGU_ADD_SUCCESS';
export const CGU_ADD_FAIL = 'CGU_ADD_FAIL';
export const CGU_ADD_RESET = 'CGU_ADD_RESET';

export const CGU_DELETE_REQUEST = 'CGU_DELETE_REQUEST';
export const CGU_DELETE_SUCCESS = 'CGU_DELETE_SUCCESS';
export const CGU_DELETE_FAIL = 'CGU_DELETE_FAIL';
export const CGU_DELETE_RESET = 'CGU_DELETE_RESET';

export const CGU_UPDATE_REQUEST = 'CGU_UPDATE_REQUEST';
export const CGU_UPDATE_SUCCESS = 'CGU_UPDATE_SUCCESS';
export const CGU_UPDATE_FAIL = 'CGU_UPDATE_FAIL';
export const CGU_UPDATE_RESET = 'CGU_UPDATE_RESET';
