// DataCounter.js
import React from 'react';
import './DataCounter.css'; // Assurez-vous d'avoir un fichier CSS pour ce composant si nécessaire

const DataCounter = ({ title, count }) => {
  return (
    <div className="counter">
      <p>{title}</p>
      <h3>{count}</h3>
    </div>
  );
};

export default DataCounter;
