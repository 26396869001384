import {
    TUTORIEL_INDEX_LIST_REQUEST,
    TUTORIEL_INDEX_LIST_SUCCESS,
    TUTORIEL_INDEX_LIST_FAIL,

    TUTORIEL_INDEX_ADD_REQUEST,
    TUTORIEL_INDEX_ADD_SUCCESS,
    TUTORIEL_INDEX_ADD_FAIL,
    TUTORIEL_INDEX_ADD_RESET,

    TUTORIEL_INDEX_DELETE_REQUEST,
    TUTORIEL_INDEX_DELETE_SUCCESS,
    TUTORIEL_INDEX_DELETE_FAIL,
    TUTORIEL_INDEX_DELETE_RESET,

    TUTORIEL_INDEX_UPDATE_REQUEST,
    TUTORIEL_INDEX_UPDATE_SUCCESS,
    TUTORIEL_INDEX_UPDATE_FAIL,
    TUTORIEL_INDEX_UPDATE_RESET
} from '../constants/tutorielIndexConstants';

// Compare this snippet from user-interface/src/redux/reducers/tutorielIndexReducers.js:
export const tutorielIndexListReducer = (state = { tutorielIndexs: [] }, action) => {
    switch (action.type) {
        case TUTORIEL_INDEX_LIST_REQUEST:
            return { loading: true, tutorielIndexs: [] };
        case TUTORIEL_INDEX_LIST_SUCCESS:
            return { loading: false, tutorielIndexs: action.payload };
        case TUTORIEL_INDEX_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/tutorielIndexReducers.js:
export const tutorielIndexAddReducer = (state = {}, action) => {
    switch (action.type) {
        case TUTORIEL_INDEX_ADD_REQUEST:
            return { loading: true };
        case TUTORIEL_INDEX_ADD_SUCCESS:
            return { loading: false, success: true, tutorielIndex: action.payload };
        case TUTORIEL_INDEX_ADD_FAIL:
            return { loading: false, error: action.payload };
        case TUTORIEL_INDEX_ADD_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/tutorielIndexReducers.js:
export const tutorielIndexDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case TUTORIEL_INDEX_DELETE_REQUEST:
            return { loading: true };
        case TUTORIEL_INDEX_DELETE_SUCCESS:
            return { loading: false, success: true };
        case TUTORIEL_INDEX_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case TUTORIEL_INDEX_DELETE_RESET:
            return {};
        default:
            return state;
    }
}

// Compare this snippet from user-interface/src/redux/reducers/tutorielIndexReducers.js:
export const tutorielIndexUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case TUTORIEL_INDEX_UPDATE_REQUEST:
            return { loading: true };
        case TUTORIEL_INDEX_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case TUTORIEL_INDEX_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case TUTORIEL_INDEX_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}
