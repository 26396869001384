import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { useSelector } from 'react-redux';
import { Container } from "react-bootstrap";

import './App.css';

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Accueil from "./screens/Accueil/Accueil";
import Contact from "./screens/Contact/Contact";
import Domisimpli from "./screens/Domisimpli/Domisimpli";
import Tutoriel from "./screens/Tutoriel/Tutoriel";
import FAQ from "./screens/Divers/FAQ/FAQ";
import CGU from "./screens/Divers/CGU/CGU";
import CGV from "./screens/Divers/CGV/CGV";
import PrivacyPolicy from "./screens/Divers/PrivacyPolicy/PrivacyPolicy";
import PageNotFound from "./screens/PageNotFound/PageNotFound";
import Administration from "./screens/Administration/Administration";
import Connexion from "./screens/Connexion/Connexion";

import axios from 'axios';
import { Component, useEffect } from "react";

function App() {
  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  return (
    <Router>
      <main>
        <Header />
        {userInfo ?
          <Routes>
            {/* Routes Visiteur */}
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="/" element={<Accueil />} />
            <Route path="/accueil" element={<Accueil />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/domisimpli" element={<Domisimpli />} />

            <Route path="/tutoriels" element={<Tutoriel />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cgu" element={<CGU />} />
            <Route path="/cgv" element={<CGV />} />

            <Route path="/connexion" element={<Connexion />} />

            <Route path="/administration" element={<Administration />} />

            <Route path="*" element={<PageNotFound />} />
          </Routes>
          :
          <Container className="children"> {/* Dans SideBar */}
            <Routes>
              {/* Routes Administrateur */}
              <Route path="/" element={<Accueil />} />
              <Route path="/accueil" element={<Accueil />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/domisimpli" element={<Domisimpli />} />

              <Route path="/tutoriels" element={<Tutoriel />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/cgu" element={<CGU />} />
              <Route path="/cgv" element={<CGV />} />

              <Route path="/connexion" element={<Connexion />} />

              {/* Routes Visiteur */}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Container>
        }
        <Footer />
      </main>
    </Router>
  );
}

export default App;
